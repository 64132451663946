import { GET_STATISTICS_POPULAR_VICECAPTAINS, CLEAR_FILTERS_POPULAR_VICECAPTAINS } from 'Pages/Statistics/PopularViceCaptains/actions/actionTypes';


const initialState = {
    popularViceCaptains: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_POPULAR_VICECAPTAINS:
            return {
                ...state,
                popularViceCaptains: action.data
            }

        case CLEAR_FILTERS_POPULAR_VICECAPTAINS:
            return {
                ...state,
                popularViceCaptains: []
            }

        default:
            return state;
    }
}