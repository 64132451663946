// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Home from "@material-ui/icons/Home";
import EventNote from "@material-ui/icons/EventNote";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import Grade from "@material-ui/icons/Grade";
import Description from "@material-ui/icons/Description";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import MovieFilter from "@material-ui/icons/MovieFilter";
import AddAlert from "@material-ui/icons/AddAlert";
// core components/views
import DashboardPage from "Pages/Dashboard/screens/Dashboard";
import TeamsList from "Pages/Teams/screens/TeamsList";
import RoundsList from "Pages/Rounds/screens/RoundsList.js";
import GamesList from "Pages/Games/screens/GamesList.js";
import ChampionshipsList from "Pages/Championships/screens/ChampionshipsList.js";
import UsersList from "Pages/Statistics/Users/screens/UsersList.js";
import UsersTeamsList from "Pages/Statistics/UsersTeams/screens/UsersTeamsList.js";
import BestPerformingUsersList from "Pages/Statistics/BestPerformingUsers/screens/BestPerformingUsersList.js";
import BestPlayersList from "Pages/Statistics/BestPlayers/screens/BestPlayersList.js";
import PopularCaptainsList from "Pages/Statistics/PopularCaptains/screens/PopularCaptainsList.js";
import PopularViceCaptainsList from "Pages/Statistics/PopularViceCaptains/screens/PopularViceCaptainsList.js";
import MostBoughtPlayersList from "Pages/Statistics/MostBoughtPlayers/screens/MostBoughtPlayersList.js";
import UsedChipsList from "Pages/Statistics/UsedChips/screens/UsedChipsList.js";
import BoughtChipsList from "Pages/Statistics/BoughtChips/screens/BoughtChipsList.js";
import WildcardsList from "Pages/Statistics/Wildcards/screens/WildcardsList.js";
import PrivateLeaguesList from "Pages/Statistics/PrivateLeagues/screens/PrivateLeaguesList.js";
import StatsMainPage from "Pages/Statistics/StatsMainPage/StatsMainPage.js";
import PalmsList from "Pages/PalmsTransactions/screens/PalmsTransactionsList.js";
import AdsList from "Pages/Advertisements/screens/AdvertisementsList.js";
import Notifications from "Pages/Notifications/screens/Notifications.js";
import Inviters from "Pages/Statistics/Inviters/screens/Inviters.js"
import ChampionshipGames from "Pages/ChampionshipGames/screens/ChampionshipGames.js";
import RegisteredUsersForPeriod from "Pages/Statistics/RegisteredUsersForPeriod/screens/RegisteredUsersForPeriod"
import ActiveUsersForPeriod from "Pages/Statistics/ActiveUsersForPeriod/screens/ActiveUsersForPeriod"
import MostActiveUsersForPeriod from "Pages/Statistics/MostActiveUsersForPeriod/screens/MostActiveUsersForPeriod"

export const statisticsRoutes = [
    {
        path: "/stats/users/1",
        sidebarName: "Потребители",
        navbarName: "Потребители",
        icon: Description,
        component: UsersList
    },
    {
        path: "/stats/user-teams/1",
        sidebarName: "Отбори на Потребители",
        navbarName: "Отбори на Потребители",
        icon: Description,
        component: UsersTeamsList
    },
    {
        path: "/stats/best-users/1",
        sidebarName: "Потребители с най-добър резултат",
        navbarName: "Потребители с най-добър резултат",
        icon: Description,
        component: BestPerformingUsersList
    },
    {
        path: "/stats/best-players/1",
        sidebarName: "Футболисти с най-много точки",
        navbarName: "Футболисти с най-много точки",
        icon: Description,
        component: BestPlayersList
    },
    {
        path: "/stats/captains-popular/1",
        sidebarName: "Най-избирани капитани",
        navbarName: "Най-избирани капитани",
        icon: Description,
        component: PopularCaptainsList
    },
    {
        path: "/stats/vicecaptains-popular/1",
        sidebarName: "Най-избирани вицекапитани",
        navbarName: "Най-избирани вицекапитани",
        icon: Description,
        component: PopularViceCaptainsList
    },
    {
        path: "/stats/most-bought-players/1",
        sidebarName: "Най-купувани играчи",
        navbarName: "Най-купувани играчи",
        icon: Description,
        component: MostBoughtPlayersList
    },
    {
        path: "/stats/used-chips/1",
        sidebarName: "Използвани чипове",
        navbarName: "Използвани чипове",
        icon: Description,
        component: UsedChipsList
    },
    // {
    //     path: "/stats/bought-chips/1",
    //     sidebarName: "Закупени чипове",
    //     navbarName: "Закупени чипове",
    //     icon: Description,
    //     component: BoughtChipsList
    // },
    {
        path: "/stats/wildcards/1",
        sidebarName: "Използвани Wildcards",
        navbarName: "Използвани Wildcards",
        icon: Description,
        component: WildcardsList
    },
    {
        path: "/stats/private-leagues/1",
        sidebarName: "Частни лиги",
        navbarName: "Частни лиги",
        icon: Description,
        component: PrivateLeaguesList
    },
    {
        path: "/stats/inviters/1",
        sidebarName: "Покани",
        navbarName: "Покани",
        icon: Description,
        component: Inviters
    },
    {
        path: "/stats/registered-users-for-period/1",
        sidebarName: "Нови регистрации",
        navbarName: "Нови регистрации",
        icon: Description,
        component: RegisteredUsersForPeriod
    },
    {
        path: "/stats/active-users-for-period/1",
        sidebarName: "Активни потребители",
        navbarName: "Активни потребители",
        icon: Description,
        component: ActiveUsersForPeriod
    },
    {
        path: "/stats/most-active-users-for-period/1",
        sidebarName: "Честота на посещенията",
        navbarName: "Честота на посещенията",
        icon: Description,
        component: MostActiveUsersForPeriod
    },
     { redirect: true, path: "/", to: "/stats/users/1", navbarName: "Redirect" }

];

export const statisticsRoutes2 = [
    {
        path: "/stats/users/2",
        sidebarName: "Потребители",
        navbarName: "Потребители",
        icon: Description,
        component: UsersList
    },
    {
        path: "/stats/user-teams/2",
        sidebarName: "Отбори на Потребители",
        navbarName: "Отбори на Потребители",
        icon: Description,
        component: UsersTeamsList
    },
    {
        path: "/stats/best-users/2",
        sidebarName: "Потребители с най-добър резултат",
        navbarName: "Потребители с най-добър резултат",
        icon: Description,
        component: BestPerformingUsersList
    },
    {
        path: "/stats/best-players/2",
        sidebarName: "Футболисти с най-много точки",
        navbarName: "Футболисти с най-много точки",
        icon: Description,
        component: BestPlayersList
    },
    {
        path: "/stats/captains-popular/2",
        sidebarName: "Най-избирани капитани",
        navbarName: "Най-избирани капитани",
        icon: Description,
        component: PopularCaptainsList
    },
    {
        path: "/stats/vicecaptains-popular/2",
        sidebarName: "Най-избирани вицекапитани",
        navbarName: "Най-избирани вицекапитани",
        icon: Description,
        component: PopularViceCaptainsList
    },
    {
        path: "/stats/most-bought-players/2",
        sidebarName: "Най-купувани играчи",
        navbarName: "Най-купувани играчи",
        icon: Description,
        component: MostBoughtPlayersList
    },
    {
        path: "/stats/used-chips/2",
        sidebarName: "Използвани чипове",
        navbarName: "Използвани чипове",
        icon: Description,
        component: UsedChipsList
    },
    {
        path: "/stats/bought-chips/2",
        sidebarName: "Закупени чипове",
        navbarName: "Закупени чипове",
        icon: Description,
        component: BoughtChipsList
    },
    {
        path: "/stats/wildcards/2",
        sidebarName: "Използвани Wildcards",
        navbarName: "Използвани Wildcards",
        icon: Description,
        component: WildcardsList
    },
    {
        path: "/stats/private-leagues/2",
        sidebarName: "Частни лиги",
        navbarName: "Частни лиги",
        icon: Description,
        component: PrivateLeaguesList
    },
    {
        path: "/stats/inviters/2",
        sidebarName: "Покани",
        navbarName: "Покани",
        icon: Description,
        component: Inviters
    },
    {
        path: "/stats/registered-users-for-period/2",
        sidebarName: "Нови регистрации",
        navbarName: "Нови регистрации",
        icon: Description,
        component: RegisteredUsersForPeriod
    },
    {
        path: "/stats/active-users-for-period/2",
        sidebarName: "Активни потребители",
        navbarName: "Активни потребители",
        icon: Description,
        component: ActiveUsersForPeriod
    },
    {
        path: "/stats/most-active-users-for-period/2",
        sidebarName: "Честота на посещенията",
        navbarName: "Честота на посещенията",
        icon: Description,
        component: MostActiveUsersForPeriod
    },
     { redirect: true, path: "/", to: "/stats/users/2", navbarName: "Redirect" }

];

const teamRoutes = [
    {
        path: "/teams/1",
        sidebarName: "FPL Fantasy Manager",
        navbarName: "FPL Fantasy Manager",
        icon: Person,
        component: TeamsList,
    },
    {
        path: "/teams/2",
        sidebarName: "Premier League",
        navbarName: "Premier League",
        icon: Person,
        component: TeamsList,
    },
];

const roundRoutes = [
    {
        path: "/rounds/1",
        sidebarName: "FPL Fantasy Manager",
        navbarName: "FPL Fantasy Manager",
        icon: EventNote,
        component: RoundsList
    },
    {
        path: "/rounds/2",
        sidebarName: "Premier League",
        navbarName: "Premier League",
        icon: EventNote,
        component: RoundsList
    },
]

const gameRoutes = [
    {
        path: "/games/1",
        sidebarName: "FPL Fantasy Manager",
        navbarName: "FPL Fantasy Manager",
        icon: DirectionsRun,
        component: GamesList
    },
    {
        path: "/games/2",
        sidebarName: "Premier League",
        navbarName: "Premier League",
        icon: DirectionsRun,
        component: GamesList
    }
]

const adsRoutes = [
    {
        path: "/advertisements/1",
        sidebarName: "FPL Fantasy Manager",
        navbarName: "FPL Fantasy Manager",
        icon: MovieFilter,
        component: AdsList
    },
    {
        path: "/advertisements/2",
        sidebarName: "Premier League",
        navbarName: "Premier League",
        icon: MovieFilter,
        component: AdsList
    }
]

const notificationRoutes = [
    {
        path: "/notifications/1",
        sidebarName: "FPL Fantasy Manager",
        navbarName: "FPL Fantasy Manager",
        icon: AddAlert,
        component: Notifications,
    },
    {
        path: "/notifications/2",
        sidebarName: "Premier League",
        navbarName: "Premier League",
        icon: AddAlert,
        component: Notifications,
    }
]

const adminRoutes = [
    {
        path: "/dashboard",
        sidebarName: "Начало",
        navbarName: "Начало",
        icon: Home,
        component: DashboardPage
    },
    {
        path: "/teams/1",
        sidebarName: "Отбори",
        navbarName: "Отбори",
        icon: Person,
        component: TeamsList,
        listItems: teamRoutes
    },
    {
        path: "/rounds/1",
        sidebarName: "Кръгове",
        navbarName: "Кръгове",
        icon: EventNote,
        component: RoundsList,
        listItems: roundRoutes
    },
    {
        path: "/games/1",
        sidebarName: "Мачове",
        navbarName: "Мачове",
        icon: DirectionsRun,
        component: GamesList,
        listItems: gameRoutes
    },
    {
        path: "/championships",
        sidebarName: "Първенства",
        navbarName: "Първенства",
        icon: Grade,
        component: ChampionshipsList
    },
    // {
    //     path: "/tournaments",
    //     sidebarName: "Игри",
    //     navbarName: "Игри",
    //     icon: Grade,
    //     component: ChampionshipGames
    // },
    {
        path: "/advertisements/1",
        sidebarName: "Реклами",
        navbarName: "Реклами",
        icon: MovieFilter,
        component: AdsList,
        listItems: adsRoutes
    },
    {
        path: "/notifications/1",
        sidebarName: "Нотификации",
        navbarName: "Нотификации",
        icon: AddAlert,
        component: Notifications,
        listItems: notificationRoutes
    },
    {
        path: "/stats/1",
        sidebarName: "Статистики",
        navbarName: "Статистики",
        icon: Description,
        component: StatsMainPage,
        listItems: statisticsRoutes
    },
    {
        path: "/stats/2",
        sidebarName: "Статистики",
        navbarName: "Статистики",
        icon: Description,
        component: StatsMainPage,
        listItems: statisticsRoutes2
    },

    { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];




export const statsRoutes = [
    {
        path: "/dashboard",
        sidebarName: "Начало",
        navbarName: "Начало",
        icon: Home,
        component: DashboardPage
    },
    {
        path: "/teams",
        sidebarName: "Отбори",
        navbarName: "Отбори",
        icon: Person,
        component: TeamsList
    },

    {
        path: "/games",
        sidebarName: "Мачове",
        navbarName: "Мачове",
        icon: DirectionsRun,
        component: GamesList
    },

    { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export const ownerRoutes = [
    {
        path: "/dashboard",
        sidebarName: "Начало",
        navbarName: "Начало",
        icon: Home,
        component: DashboardPage
    },
    {
        path: "/teams",
        sidebarName: "Отбори",
        navbarName: "Отбори",
        icon: Person,
        component: TeamsList
    },
    {
        path: "/rounds",
        sidebarName: "Кръгове",
        navbarName: "Кръгове",
        icon: EventNote,
        component: RoundsList
    },
    {
        path: "/games",
        sidebarName: "Мачове",
        navbarName: "Мачове",
        icon: DirectionsRun,
        component: GamesList
    },
    {
        path: "/championships",
        sidebarName: "Първенства",
        navbarName: "Първенства",
        icon: Grade,
        component: ChampionshipsList
    },
    {
        path: "/advertisements",
        sidebarName: "Реклами",
        navbarName: "Реклами",
        icon: MovieFilter,
        component: AdsList
    },
    {
        path: "/notifications",
        sidebarName: "Нотификации",
        navbarName: "Нотификации",
        icon: AddAlert,
        component: Notifications
    },

    { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
]

export default adminRoutes;
