import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Table from "UI/Table/Table.jsx";
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import UploadImage from "UI/UploadImage/UploadImage"

import { BASE_URL, getBadgeColorForPlayerStatus, darkBlueColor } from "utils/Constants.js";
import default_image from 'assets/img/default_image.png';
import axios from "utils/axios";

import { connect } from 'react-redux';
import { getTeamPlayers, deletePlayer } from "Pages/Players/actions/players.js";
import { getTeamData, editTeam } from "Pages/Teams/actions/teams.js";
import { showNotification } from 'Toasts/actions/toasts';
import CustomModal from "UI/CustomModal/CustomModal"
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import AddPlayerForm from "Pages/Players/screens/AddPlayerForm"
import EditPlayerForm from "Pages/Players/screens/EditPlayerForm"

class TeamProfile extends React.Component {

    state = {
        confirmModalOpen: false,
        name: "",
        short_name: "",
        modalOpen: false,
        editModalOpen: false,
        player: null,
        logo: null,
        shirt: null,
        gk_shirt: null,
        diff_away: null,
        diff_host: null,
        diff_away_error: false,
        diff_host_error: false
    }

    componentWillMount() {
        this.props.getTeamData(this.props.match.params.id);
        this.props.getTeamPlayers(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            name: nextProps.editedTeam.name,
            short_name: nextProps.editedTeam.short_name,
            logo: nextProps.editedTeam.logo,
            shirt: nextProps.editedTeam.shirt,
            gk_shirt: nextProps.editedTeam.gk_shirt,
            diff_away: nextProps.editedTeam.diff_away,
            diff_host: nextProps.editedTeam.diff_host,
        });
    }

    editHandler = () => {
        let team = {
            id: this.props.editedTeam.id,
            name: this.state.name,
            short_name: this.state.short_name,
            logo: this.state.logo,
            shirt: this.state.shirt,
            gk_shirt: this.state.gk_shirt
        }
        this.props.editTeam(team)
    }

    addPlayerHandler = () => {
        this.setState({ modalOpen: true, editModalOpen: false });
    }

    closeModal = () => {
        this.setState({ modalOpen: false });
    }

    editCloseModal = () => {
        this.setState({ editModalOpen: false });
    }

    closeConfirmModalHandler = () => {
        this.setState({ confirmModalOpen: false });
    }

    editPlayerHandler = (id) => {

        var selected = this.props.players.find((t) => {
            return t.id === id;
        });
        this.setState({ modalOpen: false, editModalOpen: true, player: selected });
    }

    openConfirmDeleteHandler = (id) => {
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        if (loggedUser && loggedUser.roles && loggedUser.roles[0].id == 2) {
            this.props.showNotification(
                {
                    type: "error",
                    msg: "Само админите може да трият играч"
                }
            )

        } else {
            this.setState({
                confirmModalOpen: true,
                confirmMessage: "Сигурни ли сте че искате да изтриете играча?",
                deleteId: id
            })
        }
    }

    deletePlayerHandler = (id) => {
        this.props.deleteTeamPlayer(id);
        this.setState({ confirmModalOpen: false });
    }

    nameChangedHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    shortNameChangedHandler = (event) => {
        this.setState({ short_name: event.target.value });
    }

    diffHostHandler = event => {
        this.setState({ diff_host: event.target.value });
    }

    diffAwayHandler = event => {
        this.setState({ diff_away: event.target.value });
    }

    logoChangeHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);

            axios.post('/teams/logo', { base64: image }).then((response) => {
                this.setState({ logo: response.data.data })
            });
        }.bind(this);
    }

    shirtChangeHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);

            axios.post('/teams/shirt', { base64: image }).then((response) => {
                this.setState({ shirt: response.data.data })
            });
        }.bind(this);
    }

    gkShirtChangeHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);

            axios.post('/teams/shirt', { base64: image }).then((response) => {
                this.setState({ gk_shirt: response.data.data })
            });
        }.bind(this);
    }


    render() {
        const { classes } = this.props;

        let mappedTeamPlayers = [];
        let teamPlayersTable = null;
        if (this.props.players && this.props.players.length > 0) {
            mappedTeamPlayers = this.props.players.map(t => {
                const image = t.image ?
                    (<div className={classes.logoIcon}>
                        <img src={BASE_URL + t.image.url} className={classes.pictureSrc} alt="logo" />
                    </div>) :
                    (<div className={classes.logoIcon}>
                        <img src={default_image} className={classes.pictureSrc} alt="logo" />
                    </div>);

                const fullName = t.first_name + " " + t.last_name;
                const status = <Badge color={getBadgeColorForPlayerStatus(t.status.id)}>{t.status.name}</Badge>

                const currentPrice = t.current_price;
                const shape = t.shape;
                return {
                    tableData: [image, fullName, t.position.name, status, currentPrice, t.palms_price, shape],
                    id: t.id
                };
            });

        }

        teamPlayersTable = (

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.tableHeader}>
                            <h4 className={classes.cardTitle}>Играчи:</h4>
                            <AddButton labelName="Добави играч" onClick={() => this.addPlayerHandler()} />
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="gray"
                                tableHead={["Аватар", "Име", "Позиция", "Статус", "Текуща цена", "Daily games цена", "Форма", "  "]}
                                tableData={mappedTeamPlayers}
                                editHandler={this.editPlayerHandler}
                                deleteHandler={this.openConfirmDeleteHandler}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.deletePlayerHandler(this.state.deleteId)} />
                <CustomModal open={this.state.modalOpen} close={this.closeModal}>
                    <AddPlayerForm onClose={this.closeModal} teamId={this.props.editedTeam.id} />
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.editCloseModal} >
                    <EditPlayerForm onClose={this.editCloseModal} player={this.state.player} team_id={this.props.editedTeam.id} />
                </CustomModal>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Профил</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Име"
                                        id="name"
                                        formControlProps={{}}
                                        inputProps={{
                                            value: this.state.name,
                                            onChange: (event) => this.nameChangedHandler(event)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Кратко име"
                                        id="short_name"
                                        formControlProps={{}}
                                        inputProps={{
                                            value: this.state.short_name,
                                            onChange: (event) => this.shortNameChangedHandler(event)
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <UploadImage
                                        description="Качи лого"
                                        image={this.state.logo}
                                        onChange={this.logoChangeHandler}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <UploadImage
                                        description="Качи тениска"
                                        image={this.state.shirt}
                                        onChange={this.shirtChangeHandler}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <UploadImage
                                        description="Качи тениска на вратаря"
                                        image={this.state.gk_shirt}
                                        onChange={this.gkShirtChangeHandler}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                disabled={this.state.diff_away_error || this.state.diff_host_error}
                                onClick={this.editHandler}
                                color="warning">Редактирай</Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
                {teamPlayersTable}
            </div>
        );
    };

}

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        flexGrow: "0",
        maxWidth: "66.666667%",
        flexBasis: "66.666667%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center"
    },
    pictureSrc: {
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
    logoIcon: {
        width: "48px",
        height: "48px",
        backgroundColor: "#999",
        border: "4px solid #ccc",
        color: "#fff",
        borderRadius: "50%",
        overflow: "hidden",
        transition: "all .2s"
    },
    tableHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
};

const mapStateToProps = state => {
    return {
        players: state.players.players,
        editedTeam: state.teams.editedTeam
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTeamPlayers: (id) => dispatch(getTeamPlayers(id)),
        getTeamData: (id) => dispatch(getTeamData(id)),
        editTeam: (team) => dispatch(editTeam(team)),
        deleteTeamPlayer: id => dispatch(deletePlayer(id)),
        showNotification: (data) => dispatch(showNotification(data))

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeamProfile));
