import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx"; import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import { getAllAds, deleteAd } from 'Pages/Advertisements/actions/advertisements.js';
import { connect } from 'react-redux';
import { darkBlueColor, getBadgeColorForAdPositions, positions } from 'utils/Constants'
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import Edit from "@material-ui/icons/Edit";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import EditAdvertisement from "./EditAdvertisement.js";
import AddAdvertisement from "./AddAdvertisement.js";
import CustomModal from "UI/CustomModal/CustomModal"
import Close from "@material-ui/icons/Close";
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import { successColor, dangerColor } from "vendor/assets/jss/material-dashboard-react.jsx";


const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
};

class AdvertisementsList extends React.Component {

    state = {
        addModalOpen: false,
        confirmModalOpen: false,
        editModalOpen: false,
        editedAdvertisement: null,
    }

    componentWillMount() {

        this.props.getAllAds(this.props.match.params.id);
    }

    componentDidMount() {
        console.log(this.props.match.params.id);
        var lastIndex = window.location.href.lastIndexOf("/");
        var requiredPath = window.location.href.slice(lastIndex + 1, window.location.href.length);
        console.log(requiredPath);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            console.log(this.props.match.params.id);
            this.props.getAllAds(this.props.match.params.id);
        }
    }

    editHandler = (id) => {

        var selected = this.props.ads.find((t) => {
            return t.id === id;
        });


        this.setState({
            editedAdvertisement: selected,
            editModalOpen: true,
        });
    }

    addHandler = () => {
        this.setState({

            addModalOpen: true,
        });
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false, addModalOpen: false });
    }

    openConfirmDeleteHandler = (id) => {

        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Сигурни ли сте че искате да изтриете рекламата?",
            deleteId: id
        })
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }

    deleteHandler = (id) => {
        this.setState({ confirmModalOpen: false });
        this.props.deleteAd(id);
    }



    render() {
        const { classes } = this.props;
        let mappedTeams = [];

        if (this.props.ads && this.props.ads.length > 0) {
            mappedTeams = this.props.ads.map(t => {
                const edit = <TooltipButton labelName="Редактирай" onClick={() => this.editHandler(t.id)}> <Edit /></TooltipButton>;
                const close = (<TooltipButton labelName="Изтрий" onClick={() => this.openConfirmDeleteHandler(t.id)}> <Close /></TooltipButton>);
                const position = <Badge
                    color={getBadgeColorForAdPositions(t.position.id)}>
                    {t.position.name}</Badge>
                const status = t.is_active ? <div style={{ color: successColor }}>Активна</div> : <div style={{ color: dangerColor }}>Неактивна</div>
                return {
                    tableData: [<ImageIcon image={t.image} />, position, t.url, status, edit, close],
                    id: t.id
                };
            });
        }
        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.deleteHandler(this.state.deleteId)} />
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                    <EditAdvertisement
                        advertisement={this.state.editedAdvertisement}
                        onClose={this.closeEditModal}
                    />
                </CustomModal>
                <CustomModal open={this.state.addModalOpen} close={this.closeEditModal}>
                    <AddAdvertisement
                        onClose={this.closeEditModal}
                    />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Реклами: </h4>
                                <AddButton labelName="Добави реклама" onClick={this.addHandler} />
                            </CardHeader>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Лого", "Позиция", "Линк на рекламата", "Статус", "  ", "  "]}
                                    tableData={mappedTeams}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        ads: state.advertisements.ads,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllAds: () => dispatch(getAllAds()),
        deleteAd: (id) => dispatch(deleteAd(id))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdvertisementsList));
