import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Table from "UI/Table/Table.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";

import { getAllTeamsForChampionship, deleteTeam } from 'Pages/Teams/actions/teams.js';
import { showNotification } from 'Toasts/actions/toasts';

import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import CustomModal from "UI/CustomModal/CustomModal"
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"

import AddButton from "UI/CustomButtons/AddButton/AddButton"
import AddTeamForm from "./AddTeamForm"
import ImageIcon from "UI/ImageIcon/ImageIcon"

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
};

class TableList extends React.Component {

    state = {
        modalOpen: false,
        confirmModalOpen: false
    }

    componentDidMount() {
        this.props.getAllTeamsForChampionship(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.getAllTeamsForChampionship(this.props.match.params.id);
        }
    }

    editHandler = (id) => {
        this.props.history.push('/team/profile/' + id);
    }

    openConfirmDeleteHandler = (id) => {

        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        if (loggedUser && loggedUser.roles && loggedUser.roles[0].id == 2) {
            this.props.showNotification(
                {
                    type: "error",
                    msg: "Само админите може да трият отбор"
                }
            )

        } else {
            this.setState({
                confirmModalOpen: true,
                confirmMessage: "Сигурни ли сте че искате да изтриете отбора?",
                deleteId: id
            })
        }
    }

    deleteHandler = (id) => {
        this.setState({ confirmModalOpen: false });
        this.props.deleteTeam(id);
    }

    addHandler = (id) => {
        this.setState({ modalOpen: true });
    }

    closeModalHandler = (id) => {
        this.setState({ modalOpen: false });
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }


    render() {
        const { classes } = this.props;
        let mappedTeams = [];

        if (this.props.teams.length > 0) {
            mappedTeams = this.props.teams.map(t => {
                return {
                    tableData: [<ImageIcon image={t.logo} />, t.name, t.players],
                    id: t.id
                };
            });
        }
        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.deleteHandler(this.state.deleteId)} />
                <CustomModal open={this.state.modalOpen} close={this.closeModalHandler}>
                    <AddTeamForm onClose={this.closeModalHandler} championshipId={this.props.match.params.id} />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Отбори: </h4>
                                <AddButton labelName="Добави отбор" onClick={() => this.addHandler()} />
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHeaderColor="gray"
                                    tableHead={["Лого", "Име", "Брой Играчи", "   "]}
                                    tableData={mappedTeams}
                                    editHandler={this.editHandler}
                                    deleteHandler={this.openConfirmDeleteHandler}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        teams: state.teams.teams,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllTeamsForChampionship: (id) => dispatch(getAllTeamsForChampionship(id)),
        deleteTeam: (id) => dispatch(deleteTeam(id)),
        showNotification: (data) => dispatch(showNotification(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TableList));
