import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { InlineDatePicker } from 'material-ui-pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

const materialTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiFormControl: {
            root: {
                //width: "100%"
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: amber['600'],
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: amber.A200,
                // color: 'white',
            },
        },
        MuiPickersDay: {
            day: {
                color: amber.A900,
                '&$selected': {
                    backgroundColor: amber['800'],
                    '&:hover': {
                        backgroundColor: amber['300'],
                    }
                },
            },
            current: {
                color: amber['900'],
                backgroundColor: amber['800'],
            },

        },

        MuiPickersClock: {

            pin: {
                backgroundColor: amber['800']
            }
        },
        MuiPickersClockPointer: {

            pointer: {
                backgroundColor: amber['800']
            },
            thumb: {
                borderColor: amber['800']
            },
            noPoint: {
                backgroundColor: amber['800']
            }
        },

        MuiIconButton: {
            "&:hover": {
                backgroundColor: amber['300'],
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: amber['800'],
            },
        },
    },
});

const styles = theme => ({
    picker: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        // width: "100%"
    }
});

class BasicDateTimePicker extends PureComponent {


    handleDateChange = date => {
        this.props.onChange(date)
    };

    render() {

        const { classes } = this.props;

        return (
            <Fragment>
                <MuiThemeProvider theme={materialTheme}>
                    <div className={classes.picker}>
                        <InlineDatePicker
                            fullWidth={true}
                            shouldDisableDate={this.props.shouldDisableDate}
                            disablePast={this.props.disablePast}
                            textFieldStyle={{ width: '100%' }}
                            variant={this.props.variant}
                            showtabs={"false"}
                            autoOk
                            ampm={"false"}
                            variant="outlined"
                            value={this.props.date}
                            onChange={this.handleDateChange}
                            label={this.props.labelName}
                            openToYearSelection={this.props.openToYearSelection}
                            showtodaybutton={"false"}
                            InputProps={{
                                endAdornment: this.props.icon ? <InputAdornment position="end">{this.props.icon}</InputAdornment> : null,
                            }}
                        />
                    </div>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

export default withStyles(styles)(BasicDateTimePicker);