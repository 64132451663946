import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({


});

class TooltipButton extends React.Component {


    render() {
        const { classes } = this.props;
        return (
            <div onClick={
                this.props.onClick}>
                <Tooltip
                    id="tooltip-top"
                    title={this.props.labelName}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                >
                    <IconButton
                        aria-label={this.props.labelName}
                        className={classes.iconButton}
                    // onClick={this.props.addHandler}
                    >
                        {this.props.children}
                        {/* <AddIcon
                            className={
                                classes.tableActionButtonIcon + " " + classes.edit
                            }
                        /> */}
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
}

export default withStyles(styles)(TooltipButton);
