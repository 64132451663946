import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import GridItem from "vendor/components/Grid/GridItem.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomInput from "vendor/components/CustomInput/CustomInput.jsx";

import loginPageStyle from "Login/screens/loginPageStyle.jsx";

import icon from "assets/img/icon.png";
import { tryAuth } from 'Login/actions/auth.js';
import { connect } from 'react-redux';
import { showNotification } from "Toasts/actions/toasts";



class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillReceiveProps(nextProps) {

    let user = nextProps.user;
    if (user) {
      if (!user.roles || !user.roles[0] || (user.roles[0].id != 1 && user.roles[0].id != 2 && user.roles[0].id != 4)) {
        this.props.showNotification(
          {
            type: "error",
            msg: "Този потребител няма администраторски права"
          }
        )
        localStorage.clear();
        this.props.history.index = 0;
        return;
      }
      this.props.history.push('/dashboard');
    }
  }

  handleLogin = () => {
    const { email, password } = this.state;
    this.props.onLogin({ email, password });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.pageHeader}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <img src={icon} alt="..." className={[classes[this.state.cardAnimaton], classes.logo].join(' ')} />
                <Card className={[classes[this.state.cardAnimaton], classes.card].join(' ')}>

                  <form className={classes.form}>
                    <CardBody>
                      <CustomInput
                        labelText="Email..."
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          onChange: (evt) => this.setState({ email: evt.target.value }),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        color="warning"
                        labelText="Password"
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          onChange: (evt) => this.setState({ password: evt.target.value }),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          )
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button onClick={this.handleLogin}
                        color="warning" size="lg">
                        Login
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>

          {/* <Footer whiteFont /> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
  }
};


const mapDispatchToProps = dispatch => {
  return {
    onLogin: authData => dispatch(tryAuth(authData)),
    showNotification: (data) => dispatch(showNotification(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(LoginPage));
