import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import { darkBlueColor } from "utils/Constants"
import { addStatForGame } from "Pages/GameStats/actions/gameStats"
import { connect } from 'react-redux';
import ImageIcon from "UI/ImageIcon/ImageIcon"

class AddGameStatsForm extends React.Component {

    state = {
        player_one: {
            id: ""
        },
        player_two: {
            id: ""
        },
        minute: ""
    }

    playerChangeHandler = (id) => {
        var selected = this.props.players.find((t) => {
            return t.id === id;
        });
        this.setState({ player_one: { id: selected.id, } });
    }

    assistantChangeHandler = (id) => {
        let selected = null
        if (this.props.statType.id === 2) {
            selected = this.props.otherTeamPlayers.find((t) => {
                return t.id === id;
            });
        } else {
            selected = this.props.players.find((t) => {
                return t.id === id;
            });
        }

        this.setState({ player_two: { id: selected.id, } });
    }

    minuteChangeHandler = (event) => {
        this.setState({ minute: event.target.value });
    }

    addGameStatHandler = () => {
        this.props.onClose();
        const data = {
            game: {
                id: this.props.gameId
            },
            stat_type: {
                id: this.props.statType.id
            },
            player_one: this.state.player_one,
            player_two: this.state.player_two,
            minute: this.state.minute
        }
        this.props.addStatForGame(data, this.props.isHost)
    }


    render() {
        const { classes } = this.props;




        let mappedPlayers = [];
        if (this.props.players.length > 0) {
            mappedPlayers = this.props.players.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.image} />
                            {t.first_name}  {t.last_name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedOtherTeamPlayers = [];
        if (this.props.otherTeamPlayers.length > 0) {
            mappedOtherTeamPlayers = this.props.otherTeamPlayers.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.image} />
                            {t.first_name}  {t.last_name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }


        let assistantInput = <CustomSelect
            labelName={this.props.statType.id === 7 ? "Влизащ играч" : "Асистент"}
            selectData={this.props.statType.id === 2 ? mappedOtherTeamPlayers : mappedPlayers}
            onChange={this.assistantChangeHandler}
            value={this.state.player_two.id}
        />;
        if (this.props.statType.id === 4
            || this.props.statType.id === 10
            || this.props.statType.id === 6
            || this.props.statType.id === 9
            || this.props.statType.id === 5
            || this.props.statType.id === 8) {
            assistantInput = null;
        }

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Добави статистика</h4>
                        </CardHeader>
                        <CardBody className={classes.cardBody}>

                            <div className={classes.playersContainer}>
                                <CustomSelect
                                    labelName={this.props.statType.id === 7 ? "Излизащ играч" : "Играч"}
                                    selectData={mappedPlayers}
                                    onChange={this.playerChangeHandler}
                                    value={this.state.player_one.id}
                                />
                                {assistantInput}
                                {/* <CustomSelect
                                    labelName="Асистент"
                                    selectData={mappedPlayers}
                                    onChange={this.assistantChangeHandler}
                                    value={this.state.player_two.id}
                                /> */}
                            </div>


                            <CustomInput
                                labelText="Минута"
                                formControlProps={{}}
                                inputProps={{
                                    type: "number",
                                    value: this.state.minute,
                                    onChange: (event) => this.minuteChangeHandler(event)
                                }}
                            />


                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.addGameStatHandler}
                                color="success">
                                Добави
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };
}

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    cardBody: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    playersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addStatForGame: (data, isHost) => dispatch(addStatForGame(data, isHost))
    }
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(AddGameStatsForm));
