import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import { filterPalmsTransactions } from 'Pages/PalmsTransactions/actions/palmsTransactions.js';

import { connect } from 'react-redux';
import Badge from "vendor/components/Badge/Badge.jsx"
import { getBadgeColorForGameStatus, darkBlueColor, getBadgeColorForRoundStatus, convertDateWithTime } from 'utils/Constants'
import moment from 'moment'
import CustomModal from "UI/CustomModal/CustomModal"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import Button from "vendor/components/CustomButtons/Button.jsx";
import GlobalNotificationsForm from "./GlobalNotificationsForm.js";
import UserNotificationsForm from "./UserNotificationsForm.js";



const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class Notifications extends React.Component {

    state = {

        globalModalOpen: false,
        usersModalOpen: false,
    }

    closeModal = () => {
        this.setState({
            globalModalOpen: false,
            usersModalOpen: false
        });
    }

    globalNotificationsHandler = () => {
        this.setState({
            globalModalOpen: true,
            usersModalOpen: false
        });
    }

    userNotificationsHandler = () => {
        this.setState({
            globalModalOpen: false,
            usersModalOpen: true
        });
    }

    componentDidMount() {
        console.log(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            console.log(this.props.match.params.id);
        }
    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <CustomModal open={this.state.globalModalOpen} close={this.closeModal}>
                    <GlobalNotificationsForm
                        id={this.props.match.params.id}
                        onClose={this.closeModal}
                    />
                </CustomModal>
                <CustomModal open={this.state.usersModalOpen} close={this.closeModal}>
                    <UserNotificationsForm
                        id={this.props.match.params.id}
                        onClose={this.closeModal}
                    />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Нотификации:</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            style={{ width: "100%" }}
                                            round
                                            onClick={this.globalNotificationsHandler}
                                            color="primary">
                                            Пусни глобална нотификация
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            style={{ width: "100%" }}
                                            round
                                            onClick={this.userNotificationsHandler}
                                            color="info">
                                            Пусни нотификация към потребител
                                        </Button>
                                    </GridItem>
                                </GridContainer>

                            </CardBody>

                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        palmsTransactions: state.palmsTransactions.palmsTransactions,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        filterPalmsTransactions: (data) => dispatch(filterPalmsTransactions(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notifications));
