import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getWinners = (id) => {
    return dispatch => {

        axios.get('/stats/bestUsers?championshipId=' + id)
            .then(res => {
                dispatch(setWinners(res.data.data));
            });
    };
};

export const setWinners = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_WINNERS,
        data: data
    };
};