import { USER_SET } from '../actions/actionTypes';

const initialState = {
    user: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_SET:
            return {
                ...state,
                user: action.user
            }

        default:
            return state;
    }
}