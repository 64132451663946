import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import { BASE_URL } from "utils/Constants.js";
import default_image from 'assets/img/default_image.png';

class ImageIcon extends React.Component {


    render() {
        const { classes, big } = this.props;


        return (
            <img src={this.props.image && this.props.image.url ? (BASE_URL + this.props.image.url) : (default_image)}

                alt="Лого"
                className={big ? classes.logoIconBig : classes.logoIcon} />

        );
    };

}

const styles = {
    logoIconBig: {
        width: "8.5em",
        height: "8.5em",
        backgroundColor: "#999",
        border: "4px solid #ccc",
        color: "#fff",
        borderRadius: "50%",
        overflow: "hidden",
        transition: "all .2s",
        marginRight: "10px"
    },
    logoIcon: {
        width: "2.5em",
        height: "2.5em",
        backgroundColor: "#999",
        border: "4px solid #ccc",
        color: "#fff",
        borderRadius: "50%",
        overflow: "hidden",
        transition: "all .2s",
        marginRight: "10px"
    },

};




export default withStyles(styles)(ImageIcon);
