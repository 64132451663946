import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getPopularViceCaptains = (data) => {
    return dispatch => {

        axios.post('/stats/popularVice', data)
            .then(res => {
                dispatch(setPopularViceCaptains(res.data.data));
            });
    };
};

export const setPopularViceCaptains = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_POPULAR_VICECAPTAINS,
        data: data
    };
};

export const clearFiltersViceCaptains = () => {
    return {
        type: actionTypes.CLEAR_FILTERS_POPULAR_VICECAPTAINS,
    }
}