import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllGameStatsTypes = () => {
    return dispatch => {
        axios.get('/stats/types')
            .then(res => {
                dispatch(setAllGameStatsTypes(res.data.data));
            });
    };
};

export const setAllGameStatsTypes = (stats) => {
    return {
        type: actionTypes.GET_ALL_GAME_STATS_TYPES,
        statsTypes: stats
    };
};


export const getStatsForGame = (id) => {
    return dispatch => {
        axios.get('/games/' + id + '/live')
            .then(res => {
                dispatch(setStatsForGame(res.data.data));
            });
    };
};

export const setStatsForGame = (gameStats) => {
    return {
        type: actionTypes.GET_STATS_FOR_GAME,
        gameStats: gameStats
    };
};


export const addStatForGame = (data, isHost) => {

    return dispatch => {
        axios.post('/stats', data)
            .then(res => {
                dispatch(setAddedStatForGame(res.data.data, isHost));
                dispatch(getGameScore(data.game.id))
            });
    };
};

export const setAddedStatForGame = (gameStat, isHost) => {
    return {
        type: actionTypes.ADD_STAT,
        gameStat: gameStat,
        isHost: isHost
    };
};


export const deleteStatForGame = (gameId, id, isHost) => {
    return dispatch => {
        axios.delete('/stats/' + id)
            .then(res => {

                dispatch(setDeletedStatForGame(id, isHost));
                dispatch(getGameScore(gameId))
            });
    };
};

export const setDeletedStatForGame = (id, isHost) => {
    return {
        type: actionTypes.DELETE_STAT,
        id: id,
        isHost: isHost
    };
};


export const startFirstHalf = (id) => {
    return dispatch => {
        axios.post('/games/' + id + '/first/start')
            .then(res => {
                dispatch(setStartedFirstHalf(res.data.data));
            });
    };
};

export const setStartedFirstHalf = (gameStatus) => {
    return {
        type: actionTypes.START_FIRST_HALF,
        newStatus: gameStatus
    };
};

export const endFirstHalf = (id) => {
    return dispatch => {
        axios.post('/games/' + id + '/first/end')
            .then(res => {
                dispatch(setEndedFirstHalf(res.data.data));
            });
    };
};

export const setEndedFirstHalf = (gameStatus) => {
    return {
        type: actionTypes.END_FIRST_HALF,
        newStatus: gameStatus
    };
};


export const startSecondHalf = (id) => {
    return dispatch => {
        axios.post('/games/' + id + '/second/start')
            .then(res => {
                dispatch(setStartedSecondHalf(res.data.data));
            });
    };
};

export const setStartedSecondHalf = (gameStatus) => {
    return {
        type: actionTypes.START_SECOND_HALF,
        newStatus: gameStatus
    };
};

export const endSecondHalf = (id) => {
    return dispatch => {
        axios.post('/games/' + id + '/second/end')
            .then(res => {
                dispatch(setEndedSecondHalf(res.data.data));
            });
    };
};

export const setEndedSecondHalf = (gameStatus) => {
    return {
        type: actionTypes.END_SECOND_HALF,
        newStatus: gameStatus
    };
};

export const saveGameLive = (data) => {
    return dispatch => {
        axios.put('/games/live', data)
            .then(res => {
                dispatch(setSaveGameLive(res.data.data));
            });
    };
};

export const setSaveGameLive = (data) => {

    return {
        type: actionTypes.SAVE_GAME_LIVE,
        gameStats: data
    };
};


export const getGameScore = (id) => {
    return dispatch => {
        axios.get('/games/' + id + '/score')
            .then(res => {
                dispatch(setGameScore(res.data.data));
            });
    };
};

export const setGameScore = (data) => {
    return {
        type: actionTypes.GET_GAME_SCORE,
        gameScore: data
    };
};

