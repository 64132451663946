import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import Badge from "vendor/components/Badge/Badge.jsx"
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { darkBlueColor, getBadgeColorForGameStatus, playoffPhases } from "utils/Constants"
import { getAllTeamsForChampionship } from "Pages/Teams/actions/teams"
import { editGame, getGameStatuses } from "Pages/Games/actions/games"
import { Scrollbars } from 'react-custom-scrollbars';
import { warningColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import UploadImage from "UI/UploadImage/UploadImage"
import { connect } from 'react-redux';
import axios from "utils/axios";

class EditGameForm extends React.Component {

    state = this.props.game;

    componentDidMount() {
        console.log(this.props.game);
        this.props.getGameStatuses();
        this.props.getAllTeamsForChampionship(this.props.championshipId);
        if (!this.props.game.championship_logo) {
            this.setState({
                championship_logo: this.props.championships.find(t => t.id == this.props.championshipId).logo
            })
        }
    }

    statusChangeHandler = (id) => {
        var selectedStatus = this.props.gameStatuses.find((t) => {
            return t.id === id;
        });
        this.setState({ status: selectedStatus });
    }

    hostTeamChangedHandler = (id) => {
        var selectedTeam = this.props.teams.find((t) => {
            return t.id === id;
        });
        this.setState({ host_team: selectedTeam });
    }

    guestTeamChangedHandler = (id) => {
        var selectedTeam = this.props.teams.find((t) => {
            return t.id === id;
        });
        this.setState({ guest_team: selectedTeam });
    }

    playoffChangeHandler = (id) => {
        var selected = playoffPhases.find((t) => {
            return t.id === id;
        });

        this.setState({ playoff_phase: selected.id });
    }

    dateChangedHandler = (date) => {
        const start_date = date.format("YYYY-MM-DD HH:mm:ss");
        this.setState({ start_date: start_date });
    }

    editGameHandler = () => {
        this.props.onClose()
        const data = {
            id: this.state.id,
            host_team: {
                id: this.state.host_team.id
            },
            guest_team: {
                id: this.state.guest_team.id
            },
            start_time: this.state.start_date,
            host_score: this.state.host_score,
            guest_score: this.state.guest_score,
            status: {
                id: this.state.status.id
            },
        }
        console.log(data);
        this.props.editGame(data)
    }

    logoChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/files', { base64: image }).then((response) => {

                this.setState({ championship_logo: response.data.data });
            });
        }.bind(this);
    }

    render() {
        const { classes } = this.props;

        let mappedTeams = [];

        if (this.props.teams && this.props.teams.length > 0) {
            mappedTeams = this.props.teams.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.logo} />
                            {t.name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        //gameStatuses
        let mappedStatuses = [];

        if (this.props.gameStatuses && this.props.gameStatuses.length > 0) {

            mappedStatuses = this.props.gameStatuses.map(t => {
                return {
                    valueData: [
                        <Badge
                            key={t.id}
                            color={getBadgeColorForGameStatus(t.id)}>
                            {t.name}</Badge>
                    ],
                    id: t.id
                };
            });
        }

        let mappedPlayoffPhases = [];
        if (playoffPhases.length > 0) {
            mappedPlayoffPhases = playoffPhases.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }


        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Редактирай мач</h4>
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="Начало"
                                            onChange={this.dateChangedHandler}
                                            date={this.state.start_date}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomSelect
                                            labelName="Статус"
                                            selectData={mappedStatuses}
                                            value={this.state.status.id}
                                            onChange={this.statusChangeHandler} />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomSelect
                                            labelName="Домакин"
                                            selectData={mappedTeams}
                                            value={this.state.host_team.id}
                                            onChange={this.hostTeamChangedHandler}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomSelect
                                            labelName="Гост"
                                            selectData={mappedTeams}
                                            onChange={this.guestTeamChangedHandler}
                                            value={this.state.guest_team.id}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Scrollbars>
                        <CardFooter className={classes.cardFooter}>
                            <Button
                                onClick={this.editGameHandler}
                                color="warning">
                                Редактирай
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + warningColor
        },

    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    }
});

const mapStateToProps = state => {

    return {
        teams: state.teams.teams,
        gameStatuses: state.games.statuses,
        championships: state.championships.championships
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllTeamsForChampionship: id => dispatch(getAllTeamsForChampionship(id)),
        editGame: data => dispatch(editGame(data)),
        getGameStatuses: () => dispatch(getGameStatuses())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditGameForm));
