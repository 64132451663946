import * as actionTypes from './actionTypes'


let nextNotificationShowId = 0;
let nextNotificationHideId = 0;

export const showSpinner = () => {
    nextNotificationShowId++;
    return {
        type: actionTypes.SHOW_SPINNER,
        show: true,
        id: nextNotificationShowId
    };
};



export const hideSpinner = () => {
    nextNotificationHideId++;
    return {
        type: actionTypes.HIDE_SPINNER,
        show: false,
        id: nextNotificationHideId
    };
};
