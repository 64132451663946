import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";

const styles = theme => ({
    iconButton: {
        backgroundColor: successColor,
        color: "white",
        "&:hover": {
            backgroundColor: "rgba(76, 175, 80, 0.8)",
        }
    }

});

class AddButton extends React.Component {


    render() {
        const { classes } = this.props;
        return (
            <div onClick={
                this.props.onClick}>
                <Tooltip
                    id="tooltip-top"
                    title={this.props.labelName}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                >
                    <IconButton
                        aria-label={this.props.labelName}
                        className={classes.iconButton}
                        onClick={this.props.addHandler}
                    >
                        <AddIcon
                            className={
                                classes.tableActionButtonIcon + " " + classes.edit
                            }
                        />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
}

export default withStyles(styles)(AddButton);
