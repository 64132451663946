/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "UI/Header/Header.jsx";
import Footer from "vendor/components/Footer/Footer.jsx";
import Sidebar from "UI/SideBar/SideBar.js";
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose'

import adminRoutes from "routes/appRoutes.jsx";
import { statsRoutes, ownerRoutes } from "routes/appRoutes.jsx";
import { getAllChampionships } from "Pages/Championships/actions/championships";
import dashboardStyle from "vendor/assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "vendor/assets/img/sidebar-4.jpg";
import logo from "assets/img/icon.png";

import TeamProfile from "Pages/Teams/screens/TeamProfile.js"
import GameStatsList from "Pages/GameStats/screens/GameStatsList.js"
import TeamsList from "Pages/Teams/screens/TeamsList.js";
import RoundsList from "Pages/Rounds/screens/RoundsList.js";
import GamesList from "Pages/Games/screens/GamesList.js";
import AddTournamentForm from "Pages/ChampionshipGames/screens/AddTournamentForm.js"
import EditTournamentForm from "../Pages/ChampionshipGames/screens/EditTournamentForm";
import ViewTournamentForm from "../Pages/ChampionshipGames/screens/ViewTournamentForm";
import AdvertisementsList from "../Pages/Advertisements/screens/AdvertisementsList";
import Notifications from "../Pages/Notifications/screens/Notifications";
import StatsMainPage from "../Pages/Statistics/StatsMainPage/StatsMainPage";
const ADMIN = 1;
const STATISTIC = 2;
const OWNER = 4;

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false
        };
        this.resizeFunction = this.resizeFunction.bind(this);
    }

    componentWillMount() {
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        if (!loggedUser) {
            this.props.history.index = 0;
            this.props.history.replace('/login');
        }
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    getRoute() {
        return this.props.location.pathname !== "/login";
    }

    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        if (loggedUser) {
            this.props.getAllChampionships();
        }
    }

    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }

    getRoutesSwitch = (routes, loggedUser) => {
        return <Switch>
            <Route path="/team/profile/:id" component={TeamProfile} />
            <Route path="/game/stats/:id" component={GameStatsList} />
            <Route path="/teams/:id" component={TeamsList} />
            <Route path="/rounds/:id" component={RoundsList} />
            <Route path="/games/:id" component={GamesList} />
            <Route path="/advertisements/:id" component={AdvertisementsList} />
            <Route path="/notifications/:id" component={Notifications} />
            <Route path="/stats/:id" component={StatsMainPage} />
            <Route path="/addChampionshipGame" component={AddTournamentForm} key={99} />
            <Route path="/editChampionshipGame/:id" component={EditTournamentForm} key={98} />
            <Route path="/viewChampionshipGame/:id" component={ViewTournamentForm} key={97} />
            {routes.map((prop, key) => {
                if (prop.redirect && loggedUser) {
                    return <Redirect from={prop.path} to={prop.to} key={key} />;
                }

                return <Route path={prop.path} component={prop.component} key={key} />;
            })}

        </Switch>
    }

    render() {
       
        const { classes, ...rest } = this.props;
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

        let switchRoutes = this.getRoutesSwitch(adminRoutes, loggedUser);
        let sideBarRoutes = this.props.championships && this.props.championships.length > 0 ? adminRoutes.map(t => {
           
            if (t.path.includes("stats/1")) {
                return {
                    ...t,
                    sidebarName: `Статистики ${this.props.championships.find(championship => championship.id == 1).name}`,
                    navbarName: `Статистики ${this.props.championships.find(championship => championship.id == 1).name}`,
                }
            }
            if (t.path.includes("stats/2")) {
                return {
                    ...t,
                    sidebarName: `Статистики ${this.props.championships.find(championship => championship.id == 2).name}`,
                    navbarName: `Статистики ${this.props.championships.find(championship => championship.id == 2).name}`,
                }
            }
            if (t.path.includes("teams")
                || t.path.includes("rounds")
                || t.path.includes("games")
                || t.path.includes("advertisements")
                || t.path.includes("notifications")) {
                return {
                    ...t,
                    listItems: this.props.championships.map((championship, index) => {
                        var u = t.listItems[0];
                        var lastIndex = u.path.lastIndexOf("/");
                        var requiredPath = u.path.slice(0, lastIndex + 1);
                        return {
                            ...u,
                            path: `${requiredPath}${championship.id}`,
                            sidebarName: championship.name,
                            navbarName: championship.name
                        }
                    })

                }
            }
            return { ...t }
        }) : adminRoutes;
        if (loggedUser && loggedUser.roles && loggedUser.roles[0]) {
            switch (loggedUser.roles[0].id) {
                case ADMIN: {

                    switchRoutes = this.getRoutesSwitch(adminRoutes, loggedUser);
                    break;
                }
                case STATISTIC: {
                    sideBarRoutes = statsRoutes;
                    switchRoutes = this.getRoutesSwitch(statsRoutes, loggedUser);
                    break;
                }
                case OWNER: {
                    sideBarRoutes = ownerRoutes;
                    switchRoutes = this.getRoutesSwitch(ownerRoutes, loggedUser);
                    break;
                }
            }
        }

        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={sideBarRoutes}
                    logoText={"BAMF Admin"}
                    logo={logo}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color="orange"
                    {...rest}
                />

                <div className={classes.mainPanel} ref="mainPanel">
                    <Header
                        routes={sideBarRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />


                    <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}

MainPage.propTypes = {
    classes: PropTypes.object.isRequired
};


const mapStateToProps = state => {

    return {
        championships: state.championships.championships,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllChampionships: () => dispatch(getAllChampionships()),

    }
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(MainPage));
