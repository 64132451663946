import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import CustomCheckboxLabel from "UI/CustomCheckboxLabel/CustomCheckboxLabel";

import { darkBlueColor } from "utils/Constants"
import { addRound, getRoundStatuses } from "Pages/Rounds/actions/rounds"

import { connect } from 'react-redux';


class AddRoundForm extends React.Component {

    state = {
        name: "",
        number: "",
        championship: {
            id: 1
        },
        start_date: null,
        end_date: null,
        playoff: false
    }
    componentWillMount() {
        if (!(this.props.statuses !== undefined && this.props.statuses.length > 0)) {
            this.props.getRoundStatuses();
        }
    }

    nameChangeHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    numberChangeHandler = (event) => {
        this.setState({ number: event.target.value });
    }

    startDateChangedHandler = (date) => {
        const start_date = date.format("YYYY-MM-DD HH:mm");
        this.setState({ start_date: start_date });
    }

    endDateChangedHandler = (date) => {
        const end_date = date.format("YYYY-MM-DD HH:mm");
        this.setState({ end_date: end_date });
    }

    addRoundHandler = () => {
        this.props.addRound({ ...this.state, championship: { id: this.props.championshipId } })
        this.props.onClose()
    }

    playoffChangeHanfdler = (event) => {
        this.setState({ playoff: event.target.checked })
    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Добави кръг</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Име"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: this.state.name,
                                            onChange: (event) => this.nameChangeHandler(event)
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Номер"
                                        id="number"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: this.state.number,
                                            onChange: (event) => this.numberChangeHandler(event)
                                        }}

                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomPicker
                                        labelName="Начало"
                                        onChange={this.startDateChangedHandler}
                                        date={this.state.start_date}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomPicker
                                        labelName="Край"
                                        onChange={this.endDateChangedHandler}
                                        date={this.state.end_date}
                                    />
                                </GridItem>
                            </GridContainer>
                            
                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.addRoundHandler}
                                color="success">
                                Добави
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    }
};

const mapStateToProps = state => {
    return {
        statuses: state.rounds.statuses,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getRoundStatuses: () => dispatch(getRoundStatuses()),
        addRound: (data) => dispatch(addRound(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddRoundForm));
