import React from "react";
import ReactExport from "react-export-excel";
import Button from "vendor/components/CustomButtons/Button.jsx";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {

    render() {
        const { dataSet } = this.props;
        if (!dataSet) return null;
        return (
            <ExcelFile
                filename={"fpl_users_with_most_invites"}
                element={<Button
                    round
                    color="success">
                    Експорт
            </Button>}
            >
                <ExcelSheet data={dataSet} name="Потребители по брой покани">
                    <ExcelColumn label="Име" value="name" />
                    <ExcelColumn label="Имейл" value="email" />
                    <ExcelColumn label="Град" value="city" />
                    <ExcelColumn label="Код за покана" value="referral_code" />
                    <ExcelColumn label="Брой поканени" value="invited_people" />
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default Download;