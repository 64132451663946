import {
    GET_ALL_TEAMS,
    GET_TEAM_DATA,
    DELETE_TEAM,
    ADD_TEAM,
    EDIT_TEAM
} from 'Pages/Teams/actions/actionTypes';
import { GET_ALL_TEAMS_FOR_CHAMPIONSHIP } from '../actions/actionTypes';

const initialState = {
    teams: [],
    editedTeam: {}

}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TEAMS:
            return {
                ...state,
                teams: action.teams
            }
        case GET_TEAM_DATA:
            return {
                ...state,
                editedTeam: action.editedTeam
            }
        case DELETE_TEAM:
            return {
                ...state,
                teams: state.teams.filter(t => t.id !== action.teamId)
            }
        case GET_ALL_TEAMS_FOR_CHAMPIONSHIP:
            return {
                ...state,
                teams: action.teams
            }
        case ADD_TEAM:
            return {
                ...state,
                teams: state.teams.concat(action.team)
            }
        case EDIT_TEAM:
            let arr = state.teams.map((item, index) => {
                if (item.id === action.team.id) {
                    return action.team;
                }
                return item;
            });
            return {
                ...state,
                games: arr
            }

        default:
            return state;
    }
}