 //export const BASE_URL = 'https://bamf-fpl.barinlabs.com/api';
 export const BASE_URL = "https://fantasy.bamf.bg/api/";
//export const BASE_URL = 'https://api.fplfm.com';
//export const BASE_URL = "http://test.fplfm.bg/api";
// export const BASE_URL = 'https://test.api.fplfm.com'
export const darkBlueColor = "#14182B";
export const darkYellowColor = "#DE9222";
export const blueColor = "#49A9EA";

export const blueBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(73,169,234, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(73,169,234, 0.2)"
};

export const convertDateWithTime = (date) => {
    //TO DO - when language is switch to english change locale
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric" };
    let d = new Date();
    if (date) {
        d = new Date(date.replace(/-/g, "/"));
    }

    let format = "bg-BG";

    return d.toLocaleDateString(format, options)
}

export const convertDate = (date) => {
    //TO DO - when language is switch to english change locale
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let d = new Date();
    if (date) {
        d = new Date(date.replace(/-/g, "/"));
    }

    let format = "bg-BG";

    return d.toLocaleDateString(format, options)
}

export const convertTime = (date) => {
    //TO DO - when language is switch to english change locale
    var options = { hour: "numeric", minute: "numeric" };
    let d = new Date();
    if (date) {
        d = new Date(date.replace(/-/g, "/"));
    }

    let format = "bg-BG";

    return d.toLocaleTimeString(format, options)
}

export const sortTypes = [
    {
        id: 1,
        name: "Низходящо",
        key: "desc"
    },
    {
        id: 2,
        name: "Възходящо",
        key: "asc"
    }
];

export const sortValues = [
    {
        id: 1,
        name: "Първо име",
        key: "first_name"
    },
    {
        id: 2,
        name: "Email",
        key: "email"
    },
    {
        id: 3,
        name: "Дата на раждане",
        key: "date_of_birth"
    },
    {
        id: 4,
        name: "Създаден на",
        key: "created_at"
    },
    {
        id: 5,
        name: "Град",
        key: "city"
    }
];

export const getBadgeColorForPlayerStatus = (id) => {
    switch (id) {
        case 1: {
            return "warning"
        }
        case 2: {
            return "danger"
        }
        case 3: {
            return "primary"
        }
        case 4: {
            return "info"
        }
        case 5: {
            return "success"
        }
        default: {
            return "gray"

        }
    }
}

export const getBadgeColorForRoundStatus = (id) => {
    switch (id) {
        case 1: {
            return "success"
        }
        case 2: {
            return "warning"
        }
        case 3: {
            return "danger"
        }
        default: {
            return "gray"

        }
    }
}

export const getBadgeColorForChampionshipStatus = (id) => {
    switch (id) {
        case 1: {
            return "success"
        }
        case 2: {
            return "warning"
        }
        case 3: {
            return "danger"
        }
        default: {
            return "gray"

        }
    }
}

export const getBadgeColorForGameStatus = (id) => {
    switch (id) {
        case 1: {
            return "success"
        }
        case 2:
        case 4: {
            return "info"
        }
        case 3: {
            return "warning"
        }
        case 5: {
            return "danger"
        }
        case 6: {
            return "primary"
        }
        default: {
            return "gray"

        }
    }
}

export const getBadgeColorForAdPositions = (id) => {
    switch (id) {
        case 1: {
            return "success"
        }
        case 2: {
            return "warning"
        }

        case 3: {
            return "info"
        }
        case 4: {
            return "info"
        }
        case 5: {
            return "danger"
        }
        case 6: {
            return "danger"
        }
        case 7: {
            return "primary"
        }
        case 8: {
            return "primary"
        }
        default: {
            return "gray"

        }
    }
}


export const playoffPhases = [
    {
        name: "Кръгова фаза",
        id: 0
    },
    {
        name: "Първа шестица",
        id: 1
    },
    {
        name: "Втора осмица А",
        id: 2
    },
    {
        name: "Втора осмица Б",
        id: 3
    },

]