import { GET_MOST_ACTIVE_USERS_FOR_PERIOD } from '../actions/actionTypes';


const initialState = {
    mostActiveUsers: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_MOST_ACTIVE_USERS_FOR_PERIOD:
            return {
                ...state,
                mostActiveUsers: action.data
            }
        default:
            return state;
    }
}