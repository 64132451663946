import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getUsedChips = (data) => {
    return dispatch => {

        axios.post('/stats/usedChips', data)
            .then(res => {
                dispatch(setUsedChips(res.data.data));
            });
    };
};

export const setUsedChips = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_USED_CHIPS,
        data: data
    };
};

export const clearFiltersUsedChips = () => {
    return {
        type: actionTypes.CLEAR_FILTERS_USED_CHIPS,
    }
}