import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
    root: {
        color: amber[600],
        '&$checked': {
            color: amber[500],
        },
    },
    checked: {},
};

class CustomCheckboxLabel extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={this.props.isChecked}
                        onChange={this.props.onChange}
                        classes={{
                            root: this.props.rootClass ? this.props.rootClass : classes.root,
                            checked: classes.checked,
                        }}
                    />
                }
                label={this.props.labelName}
            />
        );
    }
}

export default withStyles(styles)(CustomCheckboxLabel);