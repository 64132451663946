import { GET_STATISTICS_USERS_TEAMS, } from 'Pages/Statistics/UsersTeams/actions/actionTypes';


const initialState = {
    usersTeams: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_USERS_TEAMS:
            return {
                ...state,
                usersTeams: action.data
            }


        default:
            return state;
    }
}