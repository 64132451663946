import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';
import indexRoutes from "routes/indexRoutes.jsx";
import { showNotification } from "Toasts/actions/toasts";
import { connect } from 'react-redux';
import Toast from "Toasts/screens/Toast";
import Spinner from "Spinner/screens/Spinner";
class App extends Component {


  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Toast />
          <Spinner />
          <Switch>
            {indexRoutes.map((prop, key) => {
              return <Route path={prop.path} component={prop.component} key={key} />;
            })}
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showNotification: () => dispatch(showNotification()),
  }
}


export default connect(null, mapDispatchToProps)(App);
