import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx"; import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import { getAllChampionships } from 'Pages/Championships/actions/championships.js';
import { connect } from 'react-redux';
import { darkBlueColor, getBadgeColorForChampionshipStatus } from 'utils/Constants'
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import Edit from "@material-ui/icons/Edit";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import EditChampionship from "./EditChampionship.js";
import CustomModal from "UI/CustomModal/CustomModal"


const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
};

class ChampionshipsList extends React.Component {

    state = {
        editModalOpen: false,
        editedChampionship: null,
    }

    componentWillMount() {
        this.props.getAllChampionships();
    }

    editHandler = (id) => {

        var selected = this.props.championships.find((t) => {
            return t.id === id;
        });
        this.setState({
            editedChampionship: selected,
            editModalOpen: true,
        });
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false });
    }



    render() {
        const { classes } = this.props;
        let mappedTeams = [];

        if (this.props.championships && this.props.championships.length > 0) {
            mappedTeams = this.props.championships.map(t => {
                const edit = <TooltipButton labelName="Редакция" onClick={() => this.editHandler(t.id)}> <Edit /></TooltipButton>;
                const status = <Badge color={getBadgeColorForChampionshipStatus(t.status.id)}>{t.status.name}</Badge>;

                return {
                    tableData: [<ImageIcon image={t.logo} />, <ImageIcon image={t.flag} />, t.name, status, edit],
                    id: t.id
                };
            });
        }
        return (
            <div>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                    <EditChampionship
                        championship={this.state.editedChampionship}
                        onClose={this.closeEditModal}
                    />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Първенства: </h4>
                            </CardHeader>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Лого", "Флаг", "Име", "Статус", "  "]}
                                    tableData={mappedTeams}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        championships: state.championships.championships,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllChampionships: () => dispatch(getAllChampionships()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChampionshipsList));
