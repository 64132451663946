import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getFilteredUsersTeams = (data) => {
    return dispatch => {

        axios.post('/stats/userteams', data)
            .then(res => {
                dispatch(setFilteredUsersTeams(res.data.data));
            });
    };
};

export const setFilteredUsersTeams = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_USERS_TEAMS,
        data: data
    };
};