import { GET_STATISTICS_BOUGHT_CHIPS, CLEAR_FILTERS_BOUGHT_CHIPS } from 'Pages/Statistics/BoughtChips/actions/actionTypes';


const initialState = {
    boughtChips: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_BOUGHT_CHIPS:
            return {
                ...state,
                boughtChips: action.data
            }

        case CLEAR_FILTERS_BOUGHT_CHIPS:
            return {
                ...state,
                boughtChips: []
            }

        default:
            return state;
    }
}