import thunk from 'redux-thunk';
import userReducer from '../Login/reducers/user';
import teamsReducer from 'Pages/Teams/reducers/teams';
import playersReducer from 'Pages/Players/reducers/players';
import roundsReducer from 'Pages/Rounds/reducers/rounds';
import gamesReducer from 'Pages/Games/reducers/games';
import championshipReducer from 'Pages/Championships/reducers/championships';
import gameStatsReducer from 'Pages/GameStats/reducers/gameStats';
import toastsReducer from "Toasts/reducers/toasts"
import spinnerReducer from "Spinner/reducers/spinner";
import palmsTransactionsReducer from "Pages/PalmsTransactions/reducers/palmsTransactions";

import statisticsUsersReducer from "Pages/Statistics/Users/reducers/users";
import statisticsUsersTeamsReducer from "Pages/Statistics/UsersTeams/reducers/usersTeams";
import statisticsWinnersReducer from "Pages/Statistics/BestPerformingUsers/reducers/winners";
import statisticsBestPlayersReducer from "Pages/Statistics/BestPlayers/reducers/bestPlayers";
import statisticsPopularCaptainsReducer from "Pages/Statistics/PopularCaptains/reducers/popularCaptains";
import statisticsPopularViceCaptainsReducer from "Pages/Statistics/PopularViceCaptains/reducers/popularViceCaptains";
import statisticsMostBoughtPlayersReducer from "Pages/Statistics/MostBoughtPlayers/reducers/mostBoughtPlayers";
import statisticsUsedChipsReducer from "Pages/Statistics/UsedChips/reducers/usedChips";
import statisticsBoughtChipsReducer from "Pages/Statistics/BoughtChips/reducers/boughtChips";
import statisticsWildcardsReducer from "Pages/Statistics/Wildcards/reducers/wildcards";
import statisticsPrivateLeaguesReducer from "Pages/Statistics/PrivateLeagues/reducers/privateLeagues";
import advertisementsReducer from "Pages/Advertisements/reducers/advertisements";
import notificationsReducer from "Pages/Notifications/reducers/notifications";
import invitersReducer from "Pages/Statistics/Inviters/reducers/invitersReducer";
import championshipGamesReducer from "Pages/ChampionshipGames/reducers/championshipGames"
import registeredUsersForPeriodReducer from "Pages/Statistics/RegisteredUsersForPeriod/reducers/registeredUsersForPeriod";
import activeUsersForPeriodReducer from "Pages/Statistics/ActiveUsersForPeriod/reducers/activeUsersForPeriod";
import mostActiveUsersForPeriodReducer from "Pages/Statistics/MostActiveUsersForPeriod/reducers/mostActiveUsersForPeriod";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    spinner: spinnerReducer,
    user: userReducer,
    teams: teamsReducer,
    players: playersReducer,
    rounds: roundsReducer,
    games: gamesReducer,
    gameStats: gameStatsReducer,
    toasts: toastsReducer,
    championships: championshipReducer,
    statisticsUsers: statisticsUsersReducer,
    statisticsUsersTeams: statisticsUsersTeamsReducer,
    statisticsWinners: statisticsWinnersReducer,
    statisticsBestPlayers: statisticsBestPlayersReducer,
    statisticsPopularCaptains: statisticsPopularCaptainsReducer,
    palmsTransactions: palmsTransactionsReducer,
    statisticsPopularViceCaptains: statisticsPopularViceCaptainsReducer,
    statisticsMostBoughtPlayers: statisticsMostBoughtPlayersReducer,
    statisticsUsedChips: statisticsUsedChipsReducer,
    statisticsBoughtChips: statisticsBoughtChipsReducer,
    statisticsWildcards: statisticsWildcardsReducer,
    statisticsPrivateLeagues: statisticsPrivateLeaguesReducer,
    advertisements: advertisementsReducer,
    notifications: notificationsReducer,
    inviters: invitersReducer,
    championshipGames: championshipGamesReducer,
    registeredUsers: registeredUsersForPeriodReducer,
    activeUsers: activeUsersForPeriodReducer,
    mostActiveUsers: mostActiveUsersForPeriodReducer
});



export default function configureStore(initialState) {
    return createStore(rootReducer, composeEnhancers(
        applyMiddleware(thunk)
    )
    );
}