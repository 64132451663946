import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getActiveUsersForPeriod = (data) => {
    return dispatch => {
        axios.get(`/stats/users/activeUsers?date_from=${data.date_from}&date_to=${data.date_to}&page=${data.page}&championship_id=${data.championship_id}`)
            .then(res => {
                dispatch(setActiveUsersForPeriod(res.data.data));
            });
    }
}

export const setActiveUsersForPeriod = data => {
    return {
        type: actionTypes.GET_ACTIVE_USERS_FOR_PERIOD,
        data
    }
}