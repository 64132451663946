import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import { darkBlueColor } from "utils/Constants"
import { deleteStatForGame } from "Pages/GameStats/actions/gameStats"
import { connect } from 'react-redux';
import RemoveButton from "UI/CustomButtons/RemoveButton/RemoveButton"
import Table from "vendor/components/Table/Table.jsx";
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"



class DeleteGameStatsForm extends React.Component {

    state = {
        confirmModalOpen: false
    }

    removeStatHandler = (id) => {
        this.setState({ confirmModalOpen: false });
        this.props.onClose();
        this.props.deleteStatForGame(this.props.gameId, id, this.props.isHost)
    }

    openConfirmDeleteHandler = (id) => {

        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Сигурни ли сте че искате да изтриете статистиката?",
            deleteId: id
        })
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }

    render() {
        const { classes } = this.props;

        let mappedStats = this.props.stats.map(t => {

            return [
                <div className={classes.statValue}>{t.player_one ? t.player_one.name : "-"}</div>,
                <div className={classes.statValue}>{t.player_two ? t.player_two.name : "няма"}</div>,
                <div className={classes.statValue}>{t.minute}</div>,
                <div className={classes.removeButton} onClick={(id) => this.openConfirmDeleteHandler(t.id)}><RemoveButton /></div>,
            ];
        });

        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.removeStatHandler(this.state.deleteId)} />
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Изтрий статистика</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <Table
                                    tableHeaderColor="gray"
                                    tableHead={["Играч", "Асистент", "Минута", " "]}
                                    tableData={mappedStats}
                                    tableCellStyle="tableCellWithoutBorder"
                                />
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridContainer>
            </div>
        );
    };
}

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
};

const mapDispatchToProps = dispatch => {
    return {
        deleteStatForGame: (gameId, id, isHost) => dispatch(deleteStatForGame(gameId, id, isHost)),
    }
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(DeleteGameStatsForm));
