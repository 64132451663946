import * as actionTypes from './actionTypes'
import axios from 'utils/axios'



export const getSettings = () => {
    return dispatch => {
        axios.get('/settings')
            .then(res => {
                dispatch(setSettings(res.data.data));
            });
    };
};



export const setSettings = (data) => {
    return {
        type: actionTypes.GET_SETTINGS,
        data: data
    };
};


export const getAllRounds = (id) => {
    return dispatch => {
        axios.get('/championships/' + id + '/rounds')
            .then(res => {
                dispatch(setAllRounds(res.data.data));
            });
    };
};



export const setAllRounds = (rounds) => {
    return {
        type: actionTypes.GET_ALL_ROUNDS,
        rounds: rounds
    };
};


export const deleteRound = (id) => {
    return dispatch => {
        axios.delete('/rounds/' + id)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setDeletedRound(id));
                }
            });
    };
};



export const setDeletedRound = (id) => {
    return {
        type: actionTypes.DELETE_ROUND,
        roundId: id
    };
};


export const addRound = (data) => {
    return dispatch => {
        axios.post('/rounds', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setAddedRound(res.data.data));
                }
            });
    };
};



export const setAddedRound = (round) => {
    return {
        type: actionTypes.ADD_ROUND,
        newRound: round
    };
};


export const editRound = (data) => {
    return dispatch => {
        axios.put('/rounds', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setEditedRound(res.data.data));
                }
            });
    };
};



export const setEditedRound = (round) => {
    return {
        type: actionTypes.EDIT_ROUND,
        round: round
    };
};


export const getRoundStatuses = () => {
    return dispatch => {
        axios.get('/championships/statuses')
            .then(res => {
                dispatch(setRoundStatuses(res.data.data));
            });
    };
};



export const setRoundStatuses = (statuses) => {
    return {
        type: actionTypes.GET_ROUND_STATUSES,
        statuses: statuses
    };
};

export const closeActiveRound = (id) => {
    return dispatch => {
        axios.post('/rounds/close', {championship_id: id})
            .then(res => {
                dispatch(getAllRounds(id));
            });
    };
};

export const makeChanges = (id) => {
    return dispatch => {
        axios.get('/cron/swaps?championship_id=' + id)
            .then(res => {
                // dispatch(getAllRounds());
            });
    };
};


export const makeProfits = () => {
    return dispatch => {
        axios.post('/palms/profits')
            .then(res => {
                // dispatch(getAllRounds());
            });
    };
};

