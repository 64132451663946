import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllChampionships = () => {
    console.log("getting championships")
    return dispatch => {

        axios.get('/championships/all')
            .then(res => {
                dispatch(setAllChampionships(res.data.data));
            });
    };
};

export const setAllChampionships = (data) => {
    return {
        type: actionTypes.GET_ALL_CHAMPIONSHIPS,
        data: data
    };
};


export const editChampionship = (data) => {
    return dispatch => {

        axios.put('/championships', data)
            .then(res => {
                dispatch(setEditChampionship(res.data.data));
            });
    };
};

export const setEditChampionship = (data) => {
    return {
        type: actionTypes.EDIT_CHAMPIONSHIP,
        data: data
    };
};