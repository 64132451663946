import React from 'react';
import { css } from '@emotion/core';
import { connect } from 'react-redux';
import { darkYellowColor } from "utils/Constants.js";
// First way to import
import { ClipLoader } from 'react-spinners';
// // Another way to import
// import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: ${darkYellowColor};
`;

class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }


    render() {
        return (
            <>
                {this.props.ids.length > 0 ? <div style={{
                    position: "fixed",
                    zIndex: "2000",
                    width: "100%",
                    height: "100%",
                    background: "rgb(0,0,0,0.1)",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={70}
                        color={darkYellowColor}
                        loading={this.state.loading}
                    />
                </div> : null
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        show: state.spinner.show,
        ids: state.spinner.ids
    }
};

export default connect(mapStateToProps)(Spinner);