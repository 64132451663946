import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getBestPlayers = (data) => {
    return dispatch => {

        axios.post('/stats/bestPlayers', data)
            .then(res => {
                dispatch(setBestPlayers(res.data.data));
            });
    };
};

export const setBestPlayers = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_BEST_PLAYERS,
        data: data
    };
};


export const clearFiltersBestPlayers = () => {
    return {
        type: actionTypes.CLEAR_FILTERS_BEST_PLAYERS,
    }
}