import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit * 2,
        minWidth: "200px",
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    menuItem: {
        display: "flex",
        alignItem: "center",
        justifyContent: 'center'
    }
});

class SimpleSelect extends React.Component {
    state = {
        value: '',
    };

    handleChange = event => {
        this.props.onChange(event.target.value)
        this.setState({ value: event.target.value });
    };

    render() {
        const { classes } = this.props;

        let menuItems = this.props.selectData.map(item => {
            return <MenuItem key={item.id} value={item.id}> {item.valueData}</MenuItem>;
        });
        return (
            <form className={classes.root} autoComplete="off">
                <FormControl className={classes.formControl}
                    fullWidth={this.props.fullWidth}
                    margin="normal">

                    <InputLabel htmlFor="select-simple">{this.props.labelName}</InputLabel>
                    <Select

                        disabled={this.props.disabled}
                        value={this.props.value}
                        onChange={this.handleChange}
                    >
                        {menuItems}
                    </Select>
                </FormControl>
            </form>
        );
    }
}

SimpleSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSelect);
