import { FILTER_PALMS_TRANSACTIONS, } from 'Pages/PalmsTransactions/actions/actionTypes';


const initialState = {
    palmsTransactions: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case FILTER_PALMS_TRANSACTIONS:
            return {
                ...state,
                palmsTransactions: action.data
            }


        default:
            return state;
    }
}