import { GET_STATISTICS_ALL_USERS, EDIT_USER } from 'Pages/Statistics/Users/actions/actionTypes';


const initialState = {
    users: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_ALL_USERS:
            return {
                ...state,
                users: action.data
            }

        case EDIT_USER: {
            let arr = state.users.data.map((item, index) => {
                if (item.id === action.data.id) {
                    return action.data;
                }
                return item;
            });
            return {
                ...state,
                users: {
                    ...state.users,
                    data: arr
                }
            }
        }
        default:
            return state;
    }
}