import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { parse, stringify } from 'flatted/esm';
// @material-ui/icons

// core components
import tableStyle from "./tableStyle";

class CustomTable extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (!this.props.shouldCheckUpdate) return true;
    if ((this.props.tableData && !nextProps.tableData) || (!this.props.tableData && nextProps.tableData)) return true
    if (this.props.tableData && nextProps.tableData && this.props.tableData.length !== nextProps.tableData.length) return true
    var shouldUpdate = false;

    nextProps.tableData.forEach((tableDataObject, index) => {
      var tableDataArray = this.props.tableData[index];
      if (tableDataArray) {
        if (stringify({ ...tableDataArray, tableData: null }) !== stringify({ ...tableDataObject, tableData: null })) {
          shouldUpdate = true
        }
      }
    })
    return shouldUpdate;
  }

  render() {
    const { classes, tableHead, tableData, tableHeaderColor } = this.props;
    return (
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData.map((prop, key) => {

              return (
                <TableRow key={key}>
                  {prop.tableData.map((prop, key) => {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}

                </TableRow>
              );
            })}
            {tableData.length === 0 ? <h2 style={{ width: "100%", textAlign: "left", color: "gray", }}>Няма намерени резултати</h2> : null}
          </TableBody>
        </Table>
      </div>
    );
  }
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  // tableHead: PropTypes.arrayOf(PropTypes.string),
  // tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};

export default withStyles(tableStyle)(CustomTable);
