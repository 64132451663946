import { GET_STATISTICS_BEST_PLAYERS, CLEAR_FILTERS_BEST_PLAYERS } from 'Pages/Statistics/BestPlayers/actions/actionTypes';


const initialState = {
    bestPlayers: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_BEST_PLAYERS:
            return {
                ...state,
                bestPlayers: action.data
            }
        case CLEAR_FILTERS_BEST_PLAYERS:
            return {
                ...state,
                bestPlayers: []
            }


        default:
            return state;
    }
}