import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import { getAllFilteredUsers } from 'Pages/Statistics/Users/actions/users.js';
import { getAllTeamsForChampionship } from "Pages/Teams/actions/teams"
import { connect } from 'react-redux';
import { sortTypes, sortValues, getBadgeColorForGameStatus, darkBlueColor, getBadgeColorForRoundStatus, convertDate } from 'utils/Constants'
import moment from 'moment'
import Edit from "@material-ui/icons/Edit";
import CustomModal from "UI/CustomModal/CustomModal"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import Email from "@material-ui/icons/Email";
import PersonOutline from "@material-ui/icons/PersonOutline";
import Location from "@material-ui/icons/LocationOn";
import Pagination from "UI/Pagination/Pagination";
import Button from "vendor/components/CustomButtons/Button.jsx";
import EditUserForm from "./EditUserForm.js";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class UsersList extends React.Component {

    state = {
        editModalOpen: false,
        lastPage: 1,
        page: 1,
        first_name: "",
        last_name: "",
        email: "",
        city: "",
        favourite_team: {

        },
        sort: {
            value: { id: 0 },
            order: { id: 0 }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.users !== this.props.users) {
            this.setState({
                page: nextProps.users.current_page,
                lastPage: nextProps.users.last_page,
            })
        }
    }

    componentWillMount() {
        const id = window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length)
        this.props.getAllFilteredUsers({...this.state, championship_id: id});
        this.props.getAllTeamsForChampionship(id);
    }

    onChangePage = (page) => {

        let data = { ...this.state, championship_id: window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length) };
        data.page = page;
        this.props.getAllFilteredUsers(data);
    }

    handleChangeInput = (name) => event => {

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    handleSelect = (id, name) => {

        var selectedTeam = this.props.teams.find((t) => {
            return t.id === id;
        });

        this.setState({
            ...this.state,
            [name]: selectedTeam

        });
    }


    handleSortValueSelect = (id, name) => {
        var selected = sortValues.find((t) => {
            return t.id === id;
        });

        this.setState({
            ...this.state,
            sort: {
                ...this.state.sort,
                [name]: selected
            }

        });
    }

    handleSortTypeSelect = (id, name) => {
        var selected = sortTypes.find((t) => {
            return t.id === id;
        });

        this.setState({
            ...this.state,
            sort: {
                ...this.state.sort,
                [name]: selected
            }

        });
    }

    clearFilters = () => {
        let data = {
            page: 1,
            first_name: "",
            last_name: "",
            email: "",
            city: "",
            favourite_team: {

            },

        };

        this.setState({
            ...this.state,
            page: 1,
            first_name: "",
            last_name: "",
            email: "",
            city: "",
            favourite_team: {

            },
            sort: {
                value: { id: 0 },
                order: { id: 0 }
            }

        });

        this.props.getAllFilteredUsers({...data, championship_id: window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length) });
    }

    filterHandler = () => {
        let data = {
            ...this.state,
            page: 1,
            sort: {
                order: this.state.sort.order.key,
                value: this.state.sort.value.key
            }
        };

        this.props.getAllFilteredUsers({...data, championship_id: window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length) });
    }

    editHandler = (id) => {

        var selected = this.props.users.data.find((t) => {
            return t.id === id;
        });
        this.setState({
            editedUser: selected,
            editModalOpen: true,
        });
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false });
    }

    render() {
        const { classes } = this.props;

        let mappedTeams = [];
        if (this.props.teams.length > 0) {
            mappedTeams = this.props.teams.map(t => {
                return {
                    valueData: [
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.logo} />
                            {t.name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }


        let mappedSortTypes = [];

        mappedSortTypes = sortTypes.map(t => {
            return {
                valueData: [t.name],
                id: t.id
            };
        });


        let mappedSortValues = [];

        mappedSortValues = sortValues.map(t => {
            return {
                valueData: [t.name],
                id: t.id
            };
        });





        let mappedUsers = [];

        if (this.props.users && this.props.users.data && this.props.users.data.length > 0) {
            mappedUsers = this.props.users.data.map(t => {
                const avatar = <ImageIcon image={t.image} />
                const name = t.first_name + " " + t.last_name
                const dateOfBirth = convertDate(t.date_of_birth)//new Date(t.start_time).toLocaleString("en-US");
                const team = <div style={{ display: "flex", alignItems: "center" }}><ImageIcon image={t.favourite_team.logo} /> {t.favourite_team.name}</div>
                const edit = (<TooltipButton labelName="Редактирай" onClick={() => this.editHandler(t.id)}> <Edit /></TooltipButton>);
                return {
                    tableData: [avatar, name, t.email, dateOfBirth, convertDate(t.created_at), t.city, team, edit],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer>
                    <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                        <EditUserForm
                            user={this.state.editedUser}
                            onClose={this.closeEditModal}
                        />
                    </CustomModal>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Потребители: {this.props.users.total} </h4>


                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <OutlinedInput
                                            fullWidth={true}
                                            icon={<PersonOutline className={classes.inputIconsColor} />}
                                            labelName="Име"
                                            value={this.state.first_name}
                                            onChange={this.handleChangeInput("first_name")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <OutlinedInput
                                            fullWidth={true}
                                            icon={<PersonOutline className={classes.inputIconsColor} />}
                                            labelName="Фамилия"
                                            value={this.state.last_name}
                                            onChange={this.handleChangeInput("last_name")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                        <OutlinedInput
                                            fullWidth={true}
                                            icon={<Email className={classes.inputIconsColor} />}
                                            labelName="Email"
                                            value={this.state.email}
                                            onChange={this.handleChangeInput("email")} />
                                    </GridItem>


                                    <GridItem xs={12} sm={6} md={6}>
                                        <OutlinedInput
                                            fullWidth={true}
                                            icon={<Location className={classes.inputIconsColor} />}
                                            labelName="Град"
                                            value={this.state.city}
                                            onChange={this.handleChangeInput("city")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="Любим отбор"
                                            selectData={mappedTeams}
                                            value={this.state.favourite_team.id}
                                            onChange={(event) => this.handleSelect(event, "favourite_team")}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="Сортирай по поле"
                                            selectData={mappedSortValues}
                                            value={this.state.sort.value.id}
                                            onChange={(event) => this.handleSortValueSelect(event, "value")}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="Сортирай по филтър"
                                            selectData={mappedSortTypes}
                                            value={this.state.sort.order.id}
                                            onChange={(event) => this.handleSortTypeSelect(event, "order")}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.filterHandler}
                                            color="success">
                                            Филтрирай
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.clearFilters}
                                            color="danger">
                                            Изчисти филтрите
                                        </Button>
                                    </GridItem>

                                </GridContainer>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["   ", "Име", "Email", "Дата на раждане", "Създаден на", "Град", "Любим отбор", "   "]}
                                    tableData={mappedUsers}

                                />
                            </CardBody>
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.onChangePage}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        users: state.statisticsUsers.users,
        teams: state.teams.teams,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllFilteredUsers: (data) => dispatch(getAllFilteredUsers(data)),
        getAllTeamsForChampionship: id => dispatch(getAllTeamsForChampionship(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersList));
