import { GET_ALL_CHAMPIONSHIPS, EDIT_CHAMPIONSHIP } from 'Pages/Championships/actions/actionTypes';


const initialState = {
    championships: null,

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_ALL_CHAMPIONSHIPS:
            return {
                ...state,
                championships: action.data
            }
        case EDIT_CHAMPIONSHIP: {
            let arr = state.championships.map((item, index) => {
                if (item.id === action.data.id) {
                    return action.data;
                }
                return item;
            });
            return {
                ...state,
                championships: arr
            }
        }

        default:
            return state;
    }
}