import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { darkBlueColor, getBadgeColorForGameStatus, playoffPhases } from "utils/Constants"
import { sendGlobalNotification } from "Pages/Notifications/actions/notifications"
import { connect } from 'react-redux';
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import Message from "@material-ui/icons/Message";
import { primaryColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"


class GlobalNotificationsForm extends React.Component {

    state = {
        body: "",
        confirmModalOpen: false
    }

    componentDidMount() {
        this.setState({championship_id: this.props.id});
    }

    handleChangeInput = (name) => event => {

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    confirmSendNotification = () => {

        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Сигурни ли сте, че искате да пуснете Глобална Нотификация?",
        })
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }

    sendNotification = () => {

        this.props.sendGlobalNotification(this.state);
        this.closeConfirmModalHandler()
    }



    render() {
        const { classes } = this.props;

        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={this.sendNotification} />
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="primary" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Глобална нотификация</h4>
                        </CardHeader>
                        <CardBody>

                            <textarea
                                className={classes.textarea}
                                maxLength="200"
                                type="text"
                                id="body"
                                name="body"
                                placeholder="Въведете глобалната нотификация"
                                onChange={this.handleChangeInput("body")}
                            />

                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.confirmSendNotification}
                                color="primary">
                                Пусни
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = {

    textarea: {
        width: "100%",
        height: "5rem",
        outline: "none",
        padding: "1rem",
        border: "2px solid " + primaryColor,
        borderRadius: "5px",
        color: "black",
        fontSize: "0.85rem",
        boxSizing: "border-box"
    },

    cardTitle: {
        color: "white",
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },

};

const mapStateToProps = state => {

    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        sendGlobalNotification: data => dispatch(sendGlobalNotification(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GlobalNotificationsForm));
