import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import Badge from "vendor/components/Badge/Badge.jsx";
import UploadImage from "UI/UploadImage/UploadImage";
import ImageIcon from "UI/ImageIcon/ImageIcon";
import { Scrollbars } from 'react-custom-scrollbars';
import { warningColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { darkBlueColor, getBadgeColorForPlayerStatus } from "utils/Constants";
import { editPlayer, getPlayerStatuses, getPlayerPositions } from "Pages/Players/actions/players"
import { getAllTeams } from 'Pages/Teams/actions/teams.js';
import axios from "utils/axios";

import { connect } from 'react-redux';


class EditPlayerForm extends React.Component {

    state = this.props.player;

    componentWillMount() {
        this.props.getPlayerStatuses();
        this.props.getPlayerPositions();
        this.props.getAllTeams();
    }

    firstNameChangeHandler = (event) => {
        this.setState({ first_name: event.target.value });
    }

    lastNameChangeHandler = (event) => {
        this.setState({ last_name: event.target.value });
    }

    initialPriceChangeHandler = (event) => {
        this.setState({ initial_price: event.target.value });
    }

    palmsPriceChangeHandler = (event) => {
        this.setState({palms_price: event.target.value})
    }

    // currentPriceChangeHandler = (event) => {
    //     this.setState({ current_price: event.target.value });
    // }

    // shapeChangeHandler = (event) => {
    //     this.setState({ shape: event.target.value });
    // }

    statusChangeHandler = (id) => {
        var selected = this.props.statuses.find((t) => {
            return t.id === id;
        });
        this.setState({ status: selected });
    }

    positionChangeHandler = (id) => {
        var selected = this.props.positions.find((t) => {
            return t.id === id;
        });
        this.setState({ position: selected });
    }

    teamChangedHandler = (id) => {
        var selected = this.props.teams.find((t) => {
            return t.id === id;
        });
        this.setState({ team_id: selected.id, team: selected });
    }

    editHandler = () => {
        this.props.editPlayer(this.props.team_id, this.state)
        this.props.onClose()
    }

    imageChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/players/image', { base64: image }).then((response) => {
                this.setState({ image: response.data.data });
            });
        }.bind(this);
    }


    render() {
        const { classes } = this.props;

        let mappedStatuses = [];
        if (this.props.statuses.length > 0) {
            mappedStatuses = this.props.statuses.map(t => {
                return {
                    valueData: [<Badge key={t.id} color={getBadgeColorForPlayerStatus(t.id)}>{t.name}</Badge>],
                    id: t.id
                };
            });
        }


        let mappedPositions = [];
        if (this.props.positions.length > 0) {
            mappedPositions = this.props.positions.map(t => {
                return {
                    valueData: [t.name],
                    id: t.id
                };
            });
        }

        let mappedTeams = [];
        if (this.props.teams.length > 0) {
            mappedTeams = this.props.teams.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.logo} />
                            {t.name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Редактирай играч</h4>
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <UploadImage
                                            description="Качи аватар"
                                            image={this.state.image}
                                            onChange={this.imageChangedHandler}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Име"
                                            id="name"
                                            formControlProps={{}}
                                            inputProps={{
                                                value: this.state.first_name,
                                                onChange: (event) => this.firstNameChangeHandler(event)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Фамилия"
                                            id="number"
                                            formControlProps={{}}
                                            inputProps={{
                                                value: this.state.last_name,
                                                onChange: (event) => this.lastNameChangeHandler(event)
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomSelect
                                            labelName="Позиция"
                                            selectData={mappedPositions}
                                            value={this.state.position.id}
                                            onChange={this.positionChangeHandler} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomSelect
                                            labelName="Статус"
                                            selectData={mappedStatuses}
                                            value={this.state.status.id}
                                            onChange={this.statusChangeHandler} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomSelect
                                            labelName="Отбор"
                                            selectData={mappedTeams}
                                            value={this.state.team_id}
                                            onChange={this.teamChangedHandler}

                                        />
                                    </GridItem>

                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Начална цена"
                                            id="name"
                                            formControlProps={{}}
                                            inputProps={{
                                                value: this.state.initial_price,
                                                onChange: (event) => this.initialPriceChangeHandler(event)
                                            }}

                                        />
                                    </GridItem>
                                    {/* <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Daily games цена"
                                            id="name"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                value: this.state.palms_price,
                                                onChange: (event) => this.palmsPriceChangeHandler(event)
                                            }}

                                        />
                                    </GridItem> */}
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Текуща цена"
                                            id="number"
                                            formControlProps={{}}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.current_price,
                                                // onChange: (event) => this.currentPriceChangeHandler(event)
                                            }}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Форма"
                                            id="name"
                                            formControlProps={{}}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.shape,
                                                // onChange: (event) => this.shapeChangeHandler(event)
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Scrollbars>
                        <CardFooter className={classes.cardFooter}>
                            <Button
                                onClick={this.editHandler}
                                color="warning">
                                Редактирай
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = theme => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + warningColor
        },

    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    }
});

const mapStateToProps = state => {
    return {
        statuses: state.players.statuses,
        positions: state.players.positions,
        teams: state.teams.teams,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPlayerStatuses: () => dispatch(getPlayerStatuses()),
        getPlayerPositions: () => dispatch(getPlayerPositions()),
        editPlayer: (team_id, data) => dispatch(editPlayer(team_id, data)),
        getAllTeams: () => dispatch(getAllTeams()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditPlayerForm));
