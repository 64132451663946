import {
    GET_ALL_CHAMPIONSHIP_GAMES,
    GET_TOURNAMENT_BY_ID,
    ADD_TOURNAMENT,
    EDIT_TOURNAMENT,
    DELETE_TOURNAMENT,
    ADD_SUBGAME_TO_TOURNAMENT
} from 'Pages/ChampionshipGames/actions/actionTypes';
import { GET_ALL_MATCHES_FOR_CHAMPIONSHIP, DELETE_SUBTOURNAMENT, EDIT_SUBGAME, CLEAR_TOURNAMENT } from '../actions/actionTypes';


const initialState = {
    championshipGames: [],
    tournament: null,
    allMatches: []
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_ALL_CHAMPIONSHIP_GAMES:
            return {
                ...state,
                championshipGames: action.data
            }
        case GET_TOURNAMENT_BY_ID:
            return {
                ...state,
                tournament: action.data
            }
        case ADD_TOURNAMENT:
            return {
                ...state,
                championshipGames: state.championshipGames.concat(action.data),
                tournament: action.data
            }
        case EDIT_TOURNAMENT:
            return {
                ...state,
                championshipGames: state.championshipGames.map(t => {
                    if (t.id === action.data.id) {
                        return {
                            ...action.data
                        }
                    }
                    return { ...t }
                }),
                tournament: action.data
            }
        case DELETE_TOURNAMENT:
            return {
                ...state,
                championshipGames: state.championshipGames.filter(t => t.id !== action.id)
            }
        case GET_ALL_MATCHES_FOR_CHAMPIONSHIP: {
            if (state.tournament) {
                return {
                    ...state,
                    tournament: {
                        ...state.tournament,
                        championships: action.data.championships ? [...action.data.championships] : [...action.data]
                    }
                }
            } else {
                return {
                    ...state,
                    tournament: {
                        championships: [...action.data]
                    }
                }
            }
        }
        case ADD_SUBGAME_TO_TOURNAMENT: {
            console.log(state.tournament)
            if (!state.tournament) {
                return state;
            }
            return {
                ...state,
                tournament: {
                    ...state.tournament,
                    subgames: state.tournament.subgames ? state.tournament.subgames.concat(action.data) : [].concat(action.data)
                }
            }
        }
        case DELETE_SUBTOURNAMENT: {
            if (!state.tournament) {
                return state;
            }
            return {
                ...state,
                tournament: {
                    ...state.tournament,
                    subgames: state.tournament.subgames.filter(t => t.id != action.id)
                }
            }
        }
        case EDIT_SUBGAME: {
            if (!state.tournament) {
                return state;
            }
            return {
                ...state,
                tournament: {
                    ...state.tournament,
                    subgames: state.tournament.subgames.map(t => {
                        if (t.id == action.data.id) {
                            return { ...action.data }
                        }
                        return { ...t }
                    })
                }
            }
        }
        case CLEAR_TOURNAMENT: {
            return {
                ...state,
                tournament: null
            }
        }

        default:
            return state;
    }
}