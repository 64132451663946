import {
    SHOW_SPINNER,
    HIDE_SPINNER,
} from 'Spinner/actions/actionTypes';

const initialState = {
    show: false,
    ids: []

}

export default (state = initialState, action) => {
    switch (action.type) {

        case SHOW_SPINNER:
            return {
                ...state,
                show: true,
                ids: state.ids.concat(action.id)
            }
        case HIDE_SPINNER:
            return {
                ...state,
                show: false,
                ids: state.ids.filter(t => {
                    return t !== action.id
                })
            }
        default:
            return state;
    }
}