import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import { getInviters, getAllInviters } from 'Pages/Statistics/Inviters/actions/inviters.js';
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import Pagination from "UI/Pagination/Pagination";
import Download from "./Download";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class Inviters extends React.Component {

    state = {
        lastPage: 1,
        page: 1,
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.inviters !== this.props.inviters) {
            this.setState({
                page: nextProps.inviters.current_page,
                lastPage: nextProps.inviters.last_page,
            })
        }
    }

    componentDidMount() {
        this.props.getInviters(1, window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length));
        this.props.getAllInviters()
    }

    onChangePage = (page) => {
        this.props.getInviters(page, window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length));
    }

    render() {
        const { classes } = this.props;
        const inviters = this.props.inviters.data;
        let mappedInviters = [];

        if (inviters && inviters.length > 0) {
            mappedInviters = inviters.map(t => {
                return {
                    tableData: [ t.first_name + " " +  t.last_name, t.email, t.city, t.referral_code, t.invited_people],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Потребители по брой покани:  {this.props.inviters.total ? this.props.inviters.total : " "}</h4>
                                <Download dataSet={this.props.allInviters.map(t => {return {...t, name: t.first_name + " " + t.last_name}})}></Download>
                            </CardHeader>
                            <CardBody>
                              
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Име", "Имейл", "Град", "Код за покана", "Брой поканени"]}
                                    tableData={mappedInviters}

                                />

                            </CardBody>
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.onChangePage}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        inviters: state.inviters.inviters,
        allInviters: state.inviters.allInviters
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getInviters: (page, id) => dispatch(getInviters(page, id)),
        getAllInviters: () => dispatch(getAllInviters())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Inviters));
