import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getTeamPlayers = (id) => {
    return dispatch => {
        axios.get('/teams/' + id + '/players')
            .then(res => {
                dispatch(setTeamPlayers(res.data.data));
            });
    };
};

export const setTeamPlayers = (players) => {
    return {
        type: actionTypes.GET_ALL_PLAYERS,
        players: players
    };
};


export const addPlayer = (data) => {
    return dispatch => {
        axios.post('/players', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setAddedPlayer(res.data.data));
                }
            });
    };
};

export const setAddedPlayer = (player) => {
    return {
        type: actionTypes.ADD_PLAYER,
        player: player
    };
};


export const editPlayer = (team_id, data) => {
    return dispatch => {
        axios.put('/players', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setEditedPlayer(team_id, res.data.data));
                }
            });
    };
};

export const setEditedPlayer = (team_id, player) => {
    return {
        type: actionTypes.EDIT_PLAYER,
        player: player,
        ownerTeamId: team_id
    };
};


export const deletePlayer = (id) => {
    return dispatch => {
        axios.delete('/players/' + id)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setDeletedPlayer(id));
                }

            });
    };
};

export const setDeletedPlayer = (id) => {
    return {
        type: actionTypes.DELETE_PLAYER,
        playerId: id
    };
};


export const getPlayerStatuses = () => {
    return dispatch => {
        axios.get('/players/statuses')
            .then(res => {
                dispatch(setPlayerStatuses(res.data.data));
            });
    };
};

export const setPlayerStatuses = (statuses) => {
    return {
        type: actionTypes.GET_PLAYER_STATUSES,
        statuses: statuses
    };
};


export const getPlayerPositions = () => {
    return dispatch => {
        axios.get('/players/positions')
            .then(res => {
                dispatch(setPlayerPositions(res.data.data));
            });
    };
};

export const setPlayerPositions = (positions) => {
    return {
        type: actionTypes.GET_PLAYER_POSITIONS,
        positions: positions
    };
};