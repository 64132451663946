import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import { getWinners } from 'Pages/Statistics/BestPerformingUsers/actions/winners.js';
import { getAllTeamsForChampionship } from "Pages/Teams/actions/teams"
import { connect } from 'react-redux';
import { getBadgeColorForGameStatus, darkBlueColor, getBadgeColorForRoundStatus, convertDate } from 'utils/Constants'
import moment from 'moment'



const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class BestPerfomringUsersList extends React.Component {

    state = {

    }


    componentWillMount() {
        console.log(window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length))
        this.props.getWinners(window.location.href.slice(window.location.href.lastIndexOf("/") + 1, window.location.href.length));

    }


    render() {
        const { classes } = this.props;





        let mappedUsers = [];

        if (this.props.winners && this.props.winners.length > 0) {
            mappedUsers = this.props.winners.map(t => {

                const name = t.first_name + " " + t.last_name

                return {
                    tableData: [t.number, t.round_points, name, t.email, t.team_name],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Потребители с най-добър резултат: </h4>

                            </CardHeader>
                            <CardBody>

                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Номер", "Точки в кръга", "Име", "Email", "Име на отбор"]}
                                    tableData={mappedUsers}

                                />
                            </CardBody>

                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        winners: state.statisticsWinners.winners,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getWinners: (id) => dispatch(getWinners(id)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BestPerfomringUsersList));
