import {
    GET_ALL_PLAYERS,
    ADD_PLAYER,
    EDIT_PLAYER,
    DELETE_PLAYER,
    GET_PLAYER_STATUSES,
    GET_PLAYER_POSITIONS
} from 'Pages/Players/actions/actionTypes';

const initialState = {
    players: [],
    statuses: [],
    positions: [],

}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PLAYERS:
            return {
                ...state,
                players: action.players
            }
        case DELETE_PLAYER:
            return {
                ...state,
                players: state.players.filter(p => p.id !== action.playerId)
            }
        case ADD_PLAYER:
            return {
                ...state,
                players: state.players.concat(action.player)
            }
        case EDIT_PLAYER:
            if (action.player.team_id !== action.ownerTeamId) {
                return {
                    ...state,
                    players: state.players.filter(p => p.id !== action.player.id)
                }
            } else {
                let arr = state.players.map((item, index) => {
                    if (item.id === action.player.id) {
                        return action.player;
                    }
                    return item;
                });
                return {
                    ...state,
                    players: arr
                }
            }

        case GET_PLAYER_STATUSES:
            return {
                ...state,
                statuses: action.statuses
            }
        case GET_PLAYER_POSITIONS:
            return {
                ...state,
                positions: action.positions
            }
        default:
            return state;
    }
}